import {
  faDownload,
  faMagnifyingGlass,
  faRotateLeft,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  InputGroup,
  Button,
  ListGroup,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllMaids, getMaidEarnings } from "../redux/Actions/maidAction";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";

const Reports = ({ toDownload, isAdmin, type }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedMaid, setSelectedMaid] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const maidsList = useSelector((state) => state.maidsList);
  const login = useSelector((state) => state.userLogin);
  const maidEarnings = useSelector((state) => state.maidEarnings);
  const {
    loading: filterdLoading,
    earnings,
    error: filteredError,
  } = maidEarnings;
  const { userInfo } = login;
  const { loading, error, maids: allMaids } = maidsList;

  const requiredMaids = allMaids.filter((maid) => maid.type == type);
  const requiredMaidEarnings = earnings.filter((maid) => maid.type == type);
  const hotelId = userInfo.isAdmin == true ? userInfo._id : userInfo.hotel;
  const airbnbReport = type === "airbnb" ? true : false;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const calculateMaidStats = (maid) => {
    const totalEarnings = maid.tips.reduce((acc, tip) => acc + tip.amount, 0);
    const totalReviews = maid.tips.reduce(
      (acc, tip) => acc + tip.review.length,
      0
    );
    return { totalEarnings, totalReviews };
  };
  const handleModal = (maid) => {
    setSelectedMaid(maid);
    setShowModal(!showModal);
  };
  const handleDownload = async (selectedMaid) => {
    localStorage.setItem("selectedMaid", JSON.stringify(selectedMaid));
    localStorage.setItem("startDate", startDate);
    localStorage.setItem("endDate", endDate);
    localStorage.setItem("airbnbReport", airbnbReport);
    const url = `/download`;
    window.open(url, "_blank");
  };
  const handleReportDownload = async (maids) => {
    localStorage.setItem("maids", JSON.stringify(maids));
    localStorage.setItem("airbnbReport", airbnbReport);
    const url = `/download`;
    window.open(url, "_blank");
  };
  const handleFilter = () => {
    if (startDate !== "" && endDate !== "") {
      setFilterApplied(true);
      dispatch(getMaidEarnings(hotelId, startDate, endDate));
      // setFilterApplied(true);
    } else {
      alert("Please enter both start date and end date");
    }
  };
  const clearFilters = () => {
    setStartDate("");
    setEndDate("");
    setFilterApplied(false);
  };

  useEffect(() => {
    dispatch(getAllMaids(hotelId));
  }, [dispatch, filterApplied]);
  return (
    <>
      {/* ------------------------------------------------- MAID REPORT MODAL ------------------------------------------------- */}
      <Modal
        backdrop="static"
        centered
        show={showModal}
        onHide={handleModal}
        // size="lg"
      >
        <Modal.Header className="bg-primary text-light">
          <Row className="w-100">
            <Col md="auto" className="ms-auto p-0">
              <Button
                variant="outline-light p-0 px-1  m-0 border-0"
                onClick={() => setShowModal(false)}
              >
                <FontAwesomeIcon className="fs-4" icon={faXmark} />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className="report">
          {selectedMaid && (
            <>
              <h5>
                {airbnbReport
                  ? selectedMaid.serviceName
                  : selectedMaid.firstName}
                's Earning
              </h5>
              {startDate != "" && endDate != "" ? (
                <>
                  <span className="text-muted">From</span>
                  <span className="text-success mx-2">{startDate}</span>
                  <span className="text-muted">to</span>
                  <span className="text-success mx-2">{endDate}</span>
                </>
              ) : (
                <></>
              )}

              <ListGroup
                variant="flush"
                className=" p-3 my-3 rounded-1 border border-1"
              >
                <ListGroup.Item className="">
                  <Row>
                    <Col md={3} className="text-success">
                      Date
                    </Col>
                    <Col md={2} className="text-success">
                      Tip
                    </Col>
                    <Col md={7} className="text-success">
                      Reviews
                    </Col>
                    <hr className="mb-0 mt-1 p-0" />
                  </Row>
                </ListGroup.Item>
                {selectedMaid.tips.map((tip, index) => (
                  <ListGroup.Item className="py-0 my-2" key={index}>
                    <Row>
                      <Col md={3}>
                        {new Date(tip.date).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </Col>
                      <Col md={2}>${tip.amount}</Col>
                      <Col md={7}>{tip.review}</Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isAdmin || toDownload ? (
            <>
              <Button
                onClick={() => handleDownload(selectedMaid)}
                variant="success"
              >
                <FontAwesomeIcon className="pe-2" icon={faDownload} />
                {downloading ? "downloading.." : "Download"}
              </Button>
            </>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Modal>
      <Row className="p-3">
        <Col>
          {/* ----------------------------------------------- TOP ROW ------------------------------------------------------------- */}
          <Row className="mt-3 mb-5">
            <Col md={2} className="border border-1 rounded-3 text-center">
              <Form.Label>Start Date</Form.Label>
              <InputGroup className="border-0">
                <Form.Control
                  className="border-0"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col md="auto" className="my-auto">
              <span>To</span>
            </Col>
            <Col md={2} className="border border-1 rounded-3 text-center">
              <Form.Label>End Date</Form.Label>
              <InputGroup className="border-0">
                <Form.Control
                  className="border-0"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col md="auto" className="ms-auto my-auto align-items-center p-0">
              <Button variant="secondary" onClick={clearFilters}>
                <FontAwesomeIcon icon={faRotateLeft} />
              </Button>
              <Button className="mx-2" variant="success" onClick={handleFilter}>
                Apply Filter
              </Button>
              {isAdmin == true || toDownload == true ? (
                <>
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleReportDownload(
                        requiredMaidEarnings.length > 0
                          ? requiredMaidEarnings
                          : requiredMaids
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faDownload} className="pe-2" />
                    Download
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row className="">
            <ListGroup
              variant="flush"
              className=" p-0 rounded-3 border border-1"
            >
              <ListGroup.Item className="bg-primary mb-3">
                <Row>
                  {airbnbReport ? (
                    <Col md={6} className="text-light fs-5">
                      Unit name
                    </Col>
                  ) : (
                    <>
                      <Col md={3} className="text-light fs-5">
                        Name
                      </Col>
                      <Col md={2} className="text-light fs-5">
                        @
                      </Col>
                    </>
                  )}
                  <Col md={3} className="text-light fs-5">
                    Earnings
                  </Col>
                  <Col md={3} className="text-light fs-5">
                    Reviews
                  </Col>
                </Row>
              </ListGroup.Item>
              {loading || filterdLoading ? (
                <Loader />
              ) : error || filteredError ? (
                <Message>{error}</Message>
              ) : (
                <>
                  <>
                    {(requiredMaidEarnings.length > 0 && filterApplied == true
                      ? requiredMaidEarnings
                      : requiredMaids
                    ).map((maid, index) => (
                      <ListGroup.Item className="py-0" key={index}>
                        <Row className="align-items-center">
                          {airbnbReport ? (
                            <Col xs={6}>{maid.serviceName}</Col>
                          ) : (
                            <>
                              <Col md={3}>
                                {maid.firstName + " " + maid.lastName}
                              </Col>
                              <Col md={2} className="text-success">
                                {maid.serviceName}
                              </Col>
                            </>
                          )}
                          <Col md={3}>
                            ${calculateMaidStats(maid).totalEarnings}
                          </Col>
                          <Col md={2}>
                            {calculateMaidStats(maid).totalReviews}
                          </Col>
                          <Col md="auto" className="ms-auto">
                            <Button
                              variant="success"
                              onClick={() => handleModal(maid)}
                            >
                              View
                            </Button>
                          </Col>
                        </Row>
                        <hr />
                      </ListGroup.Item>
                    ))}
                  </>
                </>
              )}
            </ListGroup>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Reports;
