import React from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import ValetList from "./ValetList";
import Reports from "./Reports";
import ValetTeam from "./ValetTeam";
import VerifiedTeamTips from "./VerifiedTeamTips";

const Valets = ({ toAddMaid, toDeleteMaid, toDownload, isAdmin, canRead }) => {
  return (
    <>
      <Row className="m-0 py-4">
        <Col>
          <Tabs
            defaultActiveKey="valet"
            id="justify-tab-example"
            className="border rounded-0 nav-pills text-light"
            justify
          >
            <Tab className="" eventKey="valet" title="Valets">
              <ValetList toAddMaid={toAddMaid} toDeleteMaid={toDeleteMaid} />
            </Tab>
            <Tab eventKey="team" title="Team Tips">
              <ValetTeam canRead={canRead} />
            </Tab>
            <Tab eventKey="verifiedTips" title="Verified tips">
              <VerifiedTeamTips canRead={canRead} />
            </Tab>
            <Tab eventKey="reports" title="Reports">
              <Reports
                toDownload={toDownload}
                isAdmin={isAdmin}
                type={"valet"}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default Valets;
