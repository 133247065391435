import axios from "axios";
import { BACKEND_URL } from "../../config";
import {
  FAILED_PAYMENT_GET_ALL_FAIL,
  FAILED_PAYMENT_GET_ALL_REQUEST,
  FAILED_PAYMENT_GET_ALL_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  PAYMENT_GET_ALL_FAIL,
  PAYMENT_GET_ALL_REQUEST,
  PAYMENT_GET_ALL_SUCCESS,
} from "../Constants/paymentConstants";

export const getAllPayments = () => async (dispatch) => {
  try {
    dispatch({ type: PAYMENT_GET_ALL_REQUEST });
    const { data } = await axios.get(`${BACKEND_URL}payment/allPayments`);
    dispatch({ type: PAYMENT_GET_ALL_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: PAYMENT_GET_ALL_FAIL, payload: errorMessage });
  }
};

export const getAllFailedPayments = () => async (dispatch) => {
  try {
    dispatch({ type: FAILED_PAYMENT_GET_ALL_REQUEST });
    const { data } = await axios.get(`${BACKEND_URL}payment/failed`);
    dispatch({ type: FAILED_PAYMENT_GET_ALL_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: FAILED_PAYMENT_GET_ALL_FAIL, payload: errorMessage });
  }
};

export const getTransactions = (paymentIntentIds) => async (dispatch) => {
  try {
    dispatch({ type: GET_TRANSACTIONS_REQUEST });
    const { data } = await axios.post(`${BACKEND_URL}payment/transactions`, {
      paymentIntentIds,
    });
    dispatch({
      type: GET_TRANSACTIONS_SUCCESS,
      payload: data.transactions,
    });
  } catch (error) {
    dispatch({
      type: GET_TRANSACTIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
