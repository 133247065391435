import {
  faCircleDollarToSlot,
  faClipboardList,
  faLocationDot,
  faMagnifyingGlass,
  faMapPin,
  faPenToSquare,
  faRotateLeft,
  faTrash,
  faUserPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Modal,
  Row,
  Table,
  FloatingLabel,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { partnerRegister } from "../redux/Actions/bizDevAction";
import {
  deleteUser,
  getUserDetails,
  listUsers,
  updateHotel,
} from "../redux/Actions/userAction";
import { generatePassword } from "../controllers/passwordGenerator";
import BizDevRoperts from "./BizDevRoperts";

const BizDev = () => {
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState(null);
  const [clientTotalEarnings, setClientTotalEarnings] = useState({});
  const [userToDelete, setUserToDelete] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    location: "",
    accountType: "",
    // password: "",
    // confirmPassword: "",
    isPartner: true,
    commision: true,
  });

  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userList);
  const { loading, error, users, success } = userList;

  const partners =
    users.length > 0 ? users.filter((user) => user.isPartner == true) : [];
  const userRegister = useSelector((state) => state.partnerRegister);
  const {
    loading: registerLoading,
    error: registerError,
    success: registerSuccess,
  } = userRegister;

  const calculateTotalEarnings = (selectedPartner) => {
    if (!selectedPartner || !selectedPartner.commisionEarned) return 0;
    const total = selectedPartner.commisionEarned.reduce(
      (total, commission) => total + commission.amount,
      0
    );
    return parseFloat(total.toFixed(2));
  };
  const handleClose = () => {
    setValidated(false);
    setShow(false);
    setMessage(null);
    setFormData({
      fullName: "",
      email: "",
      location: "",
      accountType: "",
      // password: "",
      // confirmPassword: "",
      isPartner: true,
      commision: true,
    });
  };
  const handleShow = () => {
    setShow(true);
    setValidated(false);
  };
  const handleReportsModal = () => setShowReports(false);
  // const handleShow = () => setShow(true);
  const handleDeleteModal = () => setDeleteModal(false);
  const handleDelete = () => {
    if (userToDelete) {
      dispatch(deleteUser(userToDelete));
      setUserToDelete(null);
      setDeleteModal(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSave = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    } else {
      const password = generatePassword(formData.fullName);
      dispatch(
        partnerRegister(
          formData.fullName,
          formData.email,
          formData.location,
          password,
          formData.isPartner,
          formData.commision,
          formData.accountType
        )
      );
      handleClose();
    }
    setValidated(true);
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return !isNaN(date.getTime())
      ? date.toLocaleDateString("en-US", options)
      : "Invalid Date";
  };
  useEffect(() => {
    dispatch(listUsers());
  }, [dispatch, registerSuccess]);

  // useEffect(() => {
  //   if (selectedPartner && selectedPartner.commisionEarned.length > 0) {
  //     const totalEarningsByClient = selectedPartner.commisionEarned.reduce(
  //       (acc, commission) => {
  //         // Check if the timestamp is a valid date
  //         const timestampDate = new Date(commission.timestamp);
  //         if (!isNaN(timestampDate.getTime())) {
  //           const clientName = commission.clientName;
  //           acc[clientName] = acc[clientName] || {
  //             totalEarnings: 0,
  //             startDate: timestampDate,
  //             endDate: timestampDate,
  //           };
  //           acc[clientName].totalEarnings += parseFloat(
  //             commission.amount.toFixed(2)
  //           );
  //           acc[clientName].startDate = new Date(
  //             Math.min(acc[clientName].startDate, timestampDate)
  //           );
  //           acc[clientName].endDate = new Date(
  //             Math.max(acc[clientName].endDate, timestampDate)
  //           );
  //         }
  //         return acc;
  //       },
  //       {}
  //     );
  //     Object.keys(totalEarningsByClient).forEach((clientName) => {
  //       totalEarningsByClient[clientName].totalEarnings = parseFloat(
  //         totalEarningsByClient[clientName].totalEarnings.toFixed(2)
  //       );
  //     });
  //     setClientTotalEarnings(totalEarningsByClient);
  //   } else {
  //     setClientTotalEarnings({});
  //   }
  // }, [selectedPartner]);
  const styles = {
    shadow: {
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
  };
  return (
    <>
      {/* ---------------------------------------- REPORTS MODAL ------------------------------------------------- */}
      <Modal
        fullscreen
        show={showReports}
        onHide={handleReportsModal}
        backdrop="static"
        centered
      >
        <Modal.Header className="bg-primary">
          <Row className="w-100">
            <Col xs="auto" className="ms-auto p-0">
              <Button
                onClick={() => {
                  setShowReports(false);
                  setSelectedPartner(null);
                }}
              >
                <FontAwesomeIcon icon={faXmark} className=" fs-5" />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <BizDevRoperts partner={selectedPartner} />
        </Modal.Body>
      </Modal>
      {/* -------------------------------------- ADD NEW MODAL ----------------------------------------------------- */}
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header className="bg-primary text-light">
          <Row className="w-100">
            <Col xs="auto" className="fs-4">
              Add New
            </Col>
            <Col md="auto" className="ms-auto p-0">
              <Button
                variant="outline-light p-0 px-1  m-0 border-0"
                onClick={handleClose}
              >
                <FontAwesomeIcon className="fs-4" icon={faXmark} />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className="px-5">
          <Form noValidate validated={validated} onSubmit={handleSave}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                This field is required*
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter a valid Email*
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicLocation">
              <Form.Label>Address/Location</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter location/address"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                This field is required*
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              required
              className="mb-3"
              controlId="formBasicAccountType"
            >
              <Form.Label>Stripe Account:</Form.Label>
              <Row className="justify-content-center">
                <Col md="auto" className="p-0">
                  <Form.Check
                    required
                    inline
                    label="Individual"
                    value="individual"
                    name="accountType"
                    type="radio"
                    onChange={handleInputChange}
                  />
                </Col>
                <Col md="auto">
                  <Form.Check
                    inline
                    label="Business"
                    value="business"
                    name="accountType"
                    type="radio"
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Row className="my-3 mt-5">
              <Col xs="auto" className="ms-auto">
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button className="ms-3 px-5" variant="success" type="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* --------------------------------------------------- DELETE MODAL ------------------------------------------ */}
      <Modal
        show={deleteModal}
        onHide={handleDeleteModal}
        backdrop="static"
        centered
      >
        <Modal.Body className="p-4 text-center">
          <b>Are you sure you want to remove this user ?</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Row className="m-0 mt-3 p-5">
        <Col>
          <Row>
            <Col xs="auto" className="ms-auto me-5">
              <Button className="p-2 px-3 rounded-5" onClick={handleShow}>
                <FontAwesomeIcon icon={faUserPlus} className="me-2" />
                Add New
              </Button>
            </Col>
          </Row>

          <Row className="mt-5 pt-3">
            <ListGroup>
              <hr className="my-1" />
              <ListGroup.Item>
                <Row>
                  <Col md={1} className="fw-bold text-primary p-0">
                    S.No
                  </Col>
                  <Col md={2} className="fw-bold text-primary p-0">
                    Date added
                  </Col>
                  <Col className="fw-bold text-primary p-0" md={2}>
                    Name
                  </Col>
                  <Col className="fw-bold text-primary p-0" md={3}>
                    Email
                  </Col>
                  <Col className="fw-bold text-primary p-0" md={3}>
                    Location
                  </Col>
                </Row>
              </ListGroup.Item>
              <hr className="my-1 mb-3" />
              {loading || registerLoading ? (
                <Loader />
              ) : error || registerError ? (
                <Message>{error || registerError}</Message>
              ) : (
                <></>
              )}
              {partners &&
                partners.length > 0 &&
                partners
                  .slice()
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((partner, index) => (
                    <>
                      <ListGroup.Item key={index}>
                        <Row className="align-items-center">
                          <Col md={1} className="fw-bold p-0">
                            {index + 1}.
                          </Col>
                          <Col md={2} className="p-0">
                            {new Date(partner.createdAt).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </Col>
                          <Col md={2} className="p-0">
                            {partner.name}
                          </Col>
                          <Col md={3} className="p-0">
                            {partner.email}
                          </Col>
                          <Col md={2} className=" text-success p-0">
                            <FontAwesomeIcon
                              className="text-success me-2 p-0 m-0"
                              icon={faLocationDot}
                            />
                            {partner.location}
                          </Col>
                          <Col md="auto" className="ms-auto">
                            <Button
                              variant="primary"
                              className="border-0 me-3"
                              onClick={() => {
                                setShowReports(true);
                                setSelectedPartner(partner);
                              }}
                            >
                              <FontAwesomeIcon
                                className="me-2"
                                icon={faClipboardList}
                              />
                              Reports
                            </Button>
                            <Button
                              variant="danger"
                              className="border-0"
                              onClick={() => {
                                setDeleteModal(true);
                                setUserToDelete(partner._id);
                              }}
                            >
                              <FontAwesomeIcon
                                className="fs-5"
                                icon={faTrash}
                              />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <hr className="my-1" />
                    </>
                  ))}
            </ListGroup>
            {/* {loading || registerLoading ? (
              <Loader />
            ) : error || registerError ? (
              <Message>{error || registerError}</Message>
            ) : (
              <></>
            )}
            {partners &&
              partners.length > 0 &&
              partners.map((partner, index) => (
                <Col xs={3} key={index} className="my-4">
                  <Card
                    className="border-0 rounded-5 py-3"
                    style={styles.shadow}
                  >
                    <Card.Body>
                      <Row className="justify-content-center">
                        <Col xs="auto">
                          <FontAwesomeIcon
                            className="text-primary"
                            size="2x"
                            icon={faLocationDot}
                          />
                        </Col>
                      </Row>
                      <h6 className="text-center my-3 text-success">
                        {partner.location}
                      </h6>
                      <Row className="justify-content-center">
                        <Col xs="auto">
                          <span className="fw-bold">Partner :</span>{" "}
                          {partner.name}
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col xs="auto" className="text-muted">
                          {partner.email}
                        </Col>
                      </Row>
                      <Row className="justify-content-center mt-3">
                        <Col xs="auto">
                          <Button
                            variant="outline-primary"
                            className="border-0"
                          >
                            <FontAwesomeIcon
                              className="fs-5"
                              icon={faPenToSquare}
                            />
                          </Button>
                          <Button
                            variant="outline-danger"
                            className="border-0"
                            onClick={() => {
                              setDeleteModal(true);
                              setUserToDelete(partner._id);
                            }}
                          >
                            <FontAwesomeIcon className="fs-5" icon={faTrash} />
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ))} */}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BizDev;
