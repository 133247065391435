import { combineReducers, applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import {
  addUserReducer,
  getRoleUsersReducer,
  getRolesReducer,
  hotelUpdateReducer,
  resetPasswordReducer,
  roleReducer,
  roleUserUpdateReducer,
  updateRoleReducer,
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
  userRegisterReducer,
  userUpdateProfileReducer,
} from "./Reducers/userReducer";
import {
  deleteTeamMemberReducer,
  maidCreateReducer,
  maidGetAllReducer,
  maidGetEarningsReducer,
  maidUpdateReducer,
  payMaidReducer,
  staffGetAllReducer,
  teamMemberAddReducer,
  valetTeamGetReducer,
  verifyPaymentReducer,
} from "./Reducers/maidReducer";
import { teamTipReducer } from "./Reducers/teamTipReducer";
import { partnerRegisterReducer } from "./Reducers/bizDevReducer";
import {
  failedPaymentGetAllReducer,
  getTransactionsReducer,
  paymentGetAllReducer,
} from "./Reducers/paymentReducer";

const reducers = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdate: userUpdateProfileReducer,
  hotelUpdate: hotelUpdateReducer,
  userList: userListReducer,
  getRoles: getRolesReducer,
  addRole: roleReducer,
  maidsList: maidGetAllReducer,
  maidCreate: maidCreateReducer,
  maidUpdate: maidUpdateReducer,
  maidEarnings: maidGetEarningsReducer,
  payMaid: payMaidReducer,
  roleUsers: getRoleUsersReducer,
  addRoleUser: addUserReducer,
  teamTipData: teamTipReducer,
  valetTeams: valetTeamGetReducer,
  addTeamMember: teamMemberAddReducer,
  deleteTeamMember: deleteTeamMemberReducer,
  verifyPayment: verifyPaymentReducer,
  partnerRegister: partnerRegisterReducer,
  allPayments: paymentGetAllReducer,
  failedPayments: failedPaymentGetAllReducer,
  updateRole: updateRoleReducer,
  updateRoleUser: roleUserUpdateReducer,
  resetPassword: resetPasswordReducer,
  getAllStaff: staffGetAllReducer,
  transactionsList: getTransactionsReducer,
});

const userInfoFromStorage =
  localStorage.getItem("userInfo") !== null &&
  localStorage.getItem("userInfo") !== undefined
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};

const middleWares = [thunk];
const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleWares))
);
export default store;
