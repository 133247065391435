import React, { useEffect, useState } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import dockLogo from "../images/dock.png";
import {
  getUserDetails,
  listUsers,
  updateUserProfile,
} from "../redux/Actions/userAction";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminProfilePage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.userDetails);
  const { loading, error, user } = userInfo;
  const {
    loading: updateLoading,
    sucess: updateSuccess,
    error: updateError,
  } = useSelector((state) => state.userUpdate);

  const submiHandler = (e) => {
    e.preventDefault();
    if (password == confirmPassword) {
      setPasswordMatch(false);
      const updatedUser = {
        name,
        email,
        password,
      };
      dispatch(updateUserProfile(updatedUser));
    } else {
      setPasswordMatch(true);
    }
  };

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setName(user.companyName);
      setEmail(user.email);
    }
  }, [user]);

  const styles = {
    shadow: {
      boxShadow:
        " rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    },
  };
  return (
    <>
      <Row className="p-5 h-100 mt-md-5">
        <Col
          md={3}
          style={styles.shadow}
          className="border border-1 rounded-3 px-2 py-5 mx-3"
        >
          <Row className="justify-content-center">
            {user && user.isSuperAdmin ? (
              <Col sm={7}>
                <Image src={dockLogo} alt="Dock" roundedCircle fluid />
              </Col>
            ) : (
              <Col xs="auto" className="border border-1 p-5 rounded-circle">
                <FontAwesomeIcon
                  icon={faUser}
                  size="4x"
                  className="text-primary"
                />
              </Col>
            )}
          </Row>
          <Row className="my-3">
            <h4 className="text-center m-0">{name}</h4>
            <span className="text-center text-muted">{email}</span>
          </Row>
          {/* <Row className="px-5">
            <Button variant="primary">Upload Picture</Button>
          </Row> */}
        </Col>
        <Col
          md={8}
          style={styles.shadow}
          className="border border-1 rounded-3 px-5 py-3 mx-3"
        >
          <Row>
            <h4 className="text-center text-muted">Edit Details</h4>
          </Row>
          <hr />
          <Row>
            {updateLoading && <Loader />}
            {updateError && <Message>{updateError}</Message>}
            <Form onSubmit={submiHandler}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <h5 className="my-3 text-muted">Change Password</h5>
              <Row>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {passwordMatch ? (
                    <Form.Text className="text-danger">
                      Password does not match
                    </Form.Text>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Row>
              <Row>
                <Col sm="auto" className="ms-auto">
                  <Button variant="success" type="submit">
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AdminProfilePage;
