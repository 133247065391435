import {
  PARTNER_REGISTER_FAIL,
  PARTNER_REGISTER_REQUEST,
  PARTNER_REGISTER_SUCCESS,
} from "../Constants/bizDevConstants";

export const partnerRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_REGISTER_REQUEST:
      return { loading: true };
    case PARTNER_REGISTER_SUCCESS:
      return { loading: false, success: true };
    case PARTNER_REGISTER_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};
