import React, { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  InputGroup,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import bg from "../images/tipmaid-bg-7.jpg";
import card from "../images/card.png";
import logoWhite from "../images/tipmaid-logo-white.png";
import bigLogo from "../images/tipmaid-logo-tagline.png";
import logoBlue from "../images/tipmaid-logo.png";
import { BACKEND_URL } from "../config";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  const [validated, setValidated] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formRef = React.createRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    hotelName: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    numberOfRooms: "",
    tipping: [],
    card: "",
  });
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => {
      if (type === "checkbox") {
        const updatedArray = checked
          ? [...prevData[name], value]
          : prevData[name].filter((item) => item !== value);
        return {
          ...prevData,
          [name]: updatedArray,
        };
      } else if (type === "radio") {
        return {
          ...prevData,
          [name]: value,
        };
      } else {
        return {
          ...prevData,
          [name]: value,
        };
      }
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const response = await axios.post(
        `${BACKEND_URL}api/users/sendEmail`,
        formData,
        config
      );
      setFormSubmitted(response.data.success);
    }
  };

  const handleScroll = () => {
    formRef.current && formRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const navigate = useNavigate();
  const navigationHandler = () => {
    navigate("/login");
  };
  const styles = {
    gradientOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1,
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(to right,rgb(2, 23, 42, 0.8 ) 50%, rgba(0,0,0,0))",
    },
    navbar: {
      backgroundColor: "transparent",
      position: "absolute",
      zIndex: 2, // Set a higher z-index to place it above the gradient overlay
    },
    textOverlay: {
      zIndex: 2,
      position: "absolute",
      top: "40%",
      left: "5%",
      // transform: "translate(-50%, -50%)",
      color: "white",
      // fontSize: "2em",
    },
    boxShadow: {
      boxShadow: "rgba(2, 23, 42, 0.8) 10px 25px 50px -8px",
    },
  };
  return (
    <>
      {/* <Row className="vh-100 m-0">
        <Col className="vh-100 overflow-hidden p-0 position-relative">
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="dark"
            className="w-100 p-4"
            style={styles.navbar}
          >
            <Container fluid>
              <Navbar.Brand href="#home">
                <img
                  alt="TipMaid"
                  src={logoWhite}
                  width="100"
                  height="50"
                  className="d-inline-block align-top"
                />{" "}
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto ms-5">
                  <Nav.Link href="/" className="mx-3 text-center">
                    Home
                  </Nav.Link>
                  <hr className="text-success m-0 border-2" />
                  <Nav.Link href="/" className="mx-3  text-center">
                    Features
                  </Nav.Link>
                  <hr className="text-success m-0 border-2" />
                  <Nav.Link href="/" className="mx-3  text-center">
                    Pricing
                  </Nav.Link>
                  <hr className="text-success m-0 border-2" />
                </Nav>
                <Nav className="mt-3">
                  <Row className="justify-content-center">
                    <Col xs="auto">
                      <Button
                        variant="success"
                        className="px-3 rounded-5 mx-2"
                        onClick={handleScroll}
                      >
                        Book a demo
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Button
                        variant="outline-light"
                        className="px-4 mx-2 rounded-5"
                        onClick={navigationHandler}
                      >
                        Login
                      </Button>
                    </Col>
                  </Row>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Image className="w-100 h-100" src={bg} fluid />
          <div style={styles.gradientOverlay}></div>
          <Row style={styles.textOverlay}>
            <Col sm={5} className="px-5">
              <h2 className="fw-bolder">
                Let's Tidy of the Hospitality <br />
                <span className="text-success">Tipping </span>
                Experience!
              </h2>
              <p className="my-3 text-secondary">
                Our world is quickly becoming Cashless. Give your guests the
                ability to easily tip their room attendant right from their
                phone. Creating a simple experience for your guests and a
                welcome increase in income for your team.
              </p>
              <Button
                className="rounded-5 px-3 mt-3"
                variant="outline-success"
                onClick={handleScroll}
              >
                Book a Demo
              </Button>
            </Col>
          </Row>
        </Col>
      </Row> */}
      {/* <Row className="justify-content-center mt-4 m-0">
        <Col xs="auto">
          <h2 className="text-center text-primary fw-bold m-0">
            Different Experiences
          </h2>
          <hr className="mt-1 border-5 text-success" />
        </Col>
      </Row> */}
      {/* -------------------------------------------- CARDS SECTION -------------------------------------------------- */}
      {/* <Row className="m-0 p-xs-5 p-0">
        <Col>
          <Row className="justify-content-between align-items-center my-md-5">
            <Col
              sm={6}
              xs={{ order: "last" }}
              md={{ order: "first" }}
              className="text-center mb-md-0 my-5 px-5 "
            >
              <Image
                fluid
                className="rounded-5"
                style={styles.boxShadow}
                src={card}
              />
            </Col>
            <Col sm={5} className="pe-md-5 px-4 px-xs-4">
              <h4 className="text-primary fw-bolder text-center">
                Room Attensants
              </h4>
              <p className="mx-auto">
                Our world is quickly becoming Cashless. Give your guests the
                ability to easily tip their room attendant right from their
                phone. Creating a simple experience for your guests and a
                welcome increase in income for your team.{" "}
              </p>
              <Row className="justify-content-center mt-3">
                <Col xs="auto">
                  <Button
                    variant="outline-success"
                    className="rounded-5 py-2 px-3"
                    onClick={handleScroll}
                  >
                    Sign Up Today
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center my-md-5">
            <Col
              sm={5}
              className="ps-md-5 "
              xs={{ order: "first" }}
              md={{ order: "first" }}
            >
              <h4 className="text-primary fw-bolder text-center">
                Valet/Bell Staff
              </h4>
              <p>
                The tipping experience you’ve been waiting for! At present
                attendants and guests are exchanging personal information with
                Venmo or Cashapp. It’s time for a more professional and secure
                experience. Attendants can now hand out their card allowing your
                guest to easily tip them at their convenience.
              </p>
              <Row className="justify-content-center mt-3">
                <Col xs="auto">
                  <Button
                    variant="outline-success"
                    className="rounded-5 py-2 px-3"
                    onClick={handleScroll}
                  >
                    Sign Up Today
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col
              sm={6}
              className="text-center mb-md-0 my-4 px-5"
              xs={{ order: "last" }}
              md={{ order: "last" }}
            >
              <Image
                fluid
                className=" rounded-5"
                style={styles.boxShadow}
                src={card}
              />
            </Col>
          </Row>
        </Col>
      </Row> */}
      {/* ------------------------------------------------- PRICING ------------------------------------------------- */}
      {/* <Row className="justify-content-center my-4 m-0">
        <Col sm={5}>
          <hr className="mb-3 border-5 text-success" />
          <h2 className="text-center text-primary fw-bold m-0">Pricing</h2>
        </Col>
      </Row> */}
      {/* <Row className="m-0 justify-content-center mb-5">
        <Col sm={8}>
          <p className="text-center">
            TipMaid is offered as a free service to resorts and hotels by adding
            a small convenience fee to each transaction. However, there are
            upfront costs that include account set up, appreciation card design,
            printing and shipping. Set up fees are minimal and based on the size
            of the property and services selected. Sign up today for a Free
            consultation!
          </p>
          <Row className="justify-content-center my-4">
            <Col xs="auto">
              <Button
                variant="success"
                className="rounded-5 py-2 px-3"
                onClick={handleScroll}
              >
                Sign Up Today
              </Button>
            </Col>
          </Row>
        </Col>
      </Row> */}
      {/* ------------------------------------------------------------------------------------------------------------------------- */}
      {/* ------------------------------------------------ NEW DESIGN ------------------------------------------------------------- */}
      <Row className="justify-content-end bg-primary p-3 pt-4 m-0 ">
        <Col xs="auto">
          <Button
            variant="outline-light"
            className="px-4 mx-2 rounded-5 me-md-5 me-2"
            onClick={navigationHandler}
          >
            Login
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center bg-primary p-5 m-0">
        <Col md={5} className="pt-5 pb-4">
          <Image src={bigLogo} fluid />
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button
                variant="outline-success"
                className="px-4 mx-2 rounded-5 mt-3"
                onClick={() => setShowForm(true)}
              >
                Book a Demo
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* -------------------------------------------------- FORM ----------------------------------------------------- */}
      {showForm ? (
        <Row className="justify-content-around align-items-center m-0 bg-primary py-5">
          <Col
            sm={4}
            md={{ order: "firts" }}
            xs={{ order: "last" }}
            className="px-xs-5 p-md-0"
          >
            {formSubmitted ? (
              <Alert className="text-center bg-transparent border border-success text-success rounded-4">
                <FontAwesomeIcon icon={faCheck} className="px-3" />
                We will get back to you soon !
              </Alert>
            ) : (
              <Form
                ref={formRef}
                className="bg-light p-4 rounded-5"
                noValidate
                validated={validated}
                style={styles.boxShadow}
                onSubmit={submitHandler}
              >
                <FloatingLabel size="sm" label="Your Name" className="mb-3">
                  <Form.Control
                    required
                    className="rounded-4"
                    type="text"
                    placeholder="Your Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required.
                  </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel size="sm" label="Email address" className="mb-3">
                  <Form.Control
                    required
                    className="rounded-4"
                    type="email"
                    placeholder="example@email.com"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required.
                  </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel
                  size="sm"
                  label="Hotel/Resort Name"
                  className="mb-3"
                >
                  <Form.Control
                    required
                    className="rounded-4"
                    type="text"
                    placeholder="Hotel Name..."
                    name="hotelName"
                    value={formData.hotelName}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required.
                  </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel size="sm" label="Address" className="mb-3">
                  <Form.Control
                    required
                    className="rounded-4"
                    type="text"
                    placeholder="Address..."
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required.
                  </Form.Control.Feedback>
                </FloatingLabel>

                <Row>
                  <Col>
                    <FloatingLabel size="sm" label="City" className="mb-3">
                      <Form.Control
                        required
                        className="rounded-4"
                        type="text"
                        placeholder="City..."
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required.
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel size="sm" label="State" className="mb-3">
                      <Form.Control
                        required
                        className="rounded-4"
                        type="text"
                        placeholder="State..."
                        name="state"
                        value={formData.state}
                        onChange={handleInputChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required.
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel size="sm" label="Zip Code" className="mb-3">
                      <Form.Control
                        required
                        size="sm"
                        className="rounded-4"
                        type="number"
                        placeholder=""
                        name="zipCode"
                        value={formData.zipCode}
                        onChange={handleInputChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required.
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>
                <FloatingLabel
                  size="sm"
                  label="Number of Rooms"
                  className="mb-3"
                >
                  <Form.Control
                    className="rounded-4"
                    type="number"
                    placeholder=""
                    name="numberOfRooms"
                    value={formData.numberOfRooms}
                    onChange={handleInputChange}
                  />
                </FloatingLabel>
                <span className="fs-6 fw-bold">
                  Please select your tipping experience
                </span>

                <Form.Check
                  className="mt-2"
                  type="checkbox"
                  label="Room Attendant"
                  value="Room Attendants"
                  name="tipping"
                  onChange={handleInputChange}
                />
                <Form.Check
                  type="checkbox"
                  label="Valet Attendant"
                  value="Valet Attendants"
                  name="tipping"
                  onChange={handleInputChange}
                />
                <Form.Check
                  type="checkbox"
                  label="Bell Staff"
                  value="Bell Staff"
                  name="tipping"
                  onChange={handleInputChange}
                />

                <span className="fs-6 fw-bold">
                  Please select your Appreciation Card
                </span>
                <Form.Check
                  className="mt-2"
                  name="card"
                  type="radio"
                  label="Premium"
                  value="Premium"
                  onChange={handleInputChange}
                />
                <Form.Check
                  type="radio"
                  name="card"
                  label="Basic"
                  value="Basic"
                  onChange={handleInputChange}
                />
                <Row className="justify-content-center my-3">
                  <Col sm="auto">
                    <Button className="px-5 rounded-5" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
          {/* <Col
          sm={5}
          md={{ order: "last" }}
          xs={{ order: "first" }}
          className="mb-5 mb-0"
        >
          <h2 className="text-center text-light fw-bolder">Contact Us</h2>
          <hr className="text-success border-5 px-5" />
          <p className="text-light mt-4 p-0 p-xs-4">
            Please reach out and a member of our team will be in touch with you!
          </p>
          <Row className="mt-4">
            <Col xs="auto" className="mx-auto">
              <Button variant="success" className="px-5 rounded-5">
                Contact
              </Button>
            </Col>
          </Row>
        </Col> */}
        </Row>
      ) : (
        <></>
      )}
      {/* ----------------------------------------------  FOOTER ------------------------------------------------------- */}
      <Row className="p-md-4 p-0 m-0 pb-xs-2 mt-4">
        <Col className="text-center">
          <Image
            className="d-md-inline d-none"
            src={logoBlue}
            alt="TipMaid Logo"
            width="100"
            height="100"
            fluid
          />
          <Row className="mt-5 justify-content-center">
            <Col xs={3} md="auto">
              <h4 className="d-inline  fw-bold ">
                <a
                  href="/terms"
                  target="blank"
                  className="fs-4 fs-xs-5 text-primary link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                >
                  Terms
                </a>
              </h4>
            </Col>
            <Col xs={3} md="auto">
              <h4 className="d-inline-block fw-bold ">
                <a
                  href="/privacyPolicy"
                  target="blank"
                  className="text-center text-primary link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                >
                  Privacy Policy
                </a>
              </h4>
            </Col>
            <Col xs={3} md="auto">
              <h4 className="d-inline fw-bold ">
                <a
                  onClick={() => setShowForm(true)}
                  target="blank"
                  className="text-primary link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                >
                  Contact
                </a>
              </h4>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="fs-6-7 text-center pb-2 ">
        <hr className="mb-1" />
        All Rights Reserved By{" "}
        <a
          className="text-success text-decoration-none me-1"
          target="blank"
          href="https://dock.bot"
        >
          Dock, Inc
        </a>
        &copy; {new Date().getFullYear()}
      </div>
    </>
  );
};

export default Home;
