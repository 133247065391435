import {
  faCheck,
  faDownload,
  faFileArrowDown,
  faMagnifyingGlass,
  faRotateLeft,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React, { useState } from "react";
import {
  Col,
  FloatingLabel,
  Row,
  Table,
  Form,
  Dropdown,
  DropdownButton,
  Button,
} from "react-bootstrap";
import * as XLSX from "xlsx";

const SuccessfullPaymentsAdmin = ({ payments }) => {
  const [filteredPayments, setFilteredPayments] = useState();
  const [filters, setFilters] = useState({
    date: "",
    transactionId: "",
    clientName: "",
    type: "",
  });
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  console.log("payments in admin page", payments);
  const handleFilterChange = (fieldName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: value,
    }));
  };
  const filterPayments = () => {
    const filteredResults = payments.filter((payment) => {
      const dateMatch =
        !filters.date ||
        format(new Date(payment.createdAt), "yyyy-MM-dd") ===
          format(new Date(filters.date), "yyyy-MM-dd");

      const transactionIdMatch =
        !filters.transactionId ||
        payment.paymentIntentId.includes(
          filters.transactionId.replace(/\s/g, "")
        );

      const clientNameMatch =
        !filters.clientName ||
        (payment.clientName &&
          payment.clientName
            .toLowerCase()
            .replace(/\s/g, "")
            .includes(filters.clientName.toLowerCase().replace(/\s/g, "")));

      const typeMatch =
        !filters.type ||
        (payment.connectAccount === "Team Payment"
          ? "Direct Transfer"
          : "Destination Charge") === filters.type;

      return dateMatch && transactionIdMatch && clientNameMatch && typeMatch;
    });
    setFilteredPayments(filteredResults);
    // .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  };
  const clearFilters = () => {
    setFilters({
      date: "",
      transactionId: "",
      clientName: "",
      type: "",
    });
    setFilteredPayments(null);
  };
  const handleDownloadReceipt = (transactionData) => {
    const transactionDataString = encodeURIComponent(
      JSON.stringify(transactionData)
    );
    const url = `/receipt?data=${transactionDataString}`;
    const newWindow = window.open(url, "_blank");
    if (!newWindow) {
      console.error(
        "Failed to open a new window. Ensure that pop-ups are allowed."
      );
    }
  };
  const handleDownloadReports = () => {
    const table = document.querySelector(".report");
    const data = [];
    const headers = [];
    table.querySelectorAll("thead th").forEach((th) => {
      if (th.textContent.trim() !== "Receipt") {
        headers.push(th.textContent.trim());
      }
    });
    data.push(headers);
    table.querySelectorAll("tbody tr").forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell, index) => {
        if (index !== row.cells.length - 1) {
          rowData.push(cell.textContent.trim());
        }
      });
      data.push(rowData);
    });
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, "tipmaid-transactions-report.xlsx");
  };
  return (
    <>
      <Row className="m-0 mt-4">
        <Col className="p-0">
          {/* ------------------------------------------- FILTERS -------------------------------------------------------- */}
          <Row className=" mb-4 align-items-center justify-content-center">
            <Col xs={2}>
              <FloatingLabel label="Date">
                <Form.Control
                  type="date"
                  placeholder="Date"
                  value={filters.date}
                  onChange={(e) => handleFilterChange("date", e.target.value)}
                />
              </FloatingLabel>
            </Col>
            <Col xs={3}>
              <FloatingLabel label="Transaction Id">
                <Form.Control
                  type="text"
                  placeholder="Transaction Id"
                  value={filters.transactionId}
                  onChange={(e) =>
                    handleFilterChange("transactionId", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            {/* <Col xs={2}>
              <FloatingLabel label="Client Name">
                <Form.Control
                  type="text"
                  placeholder="Client Name"
                  value={filters.clientName}
                  onChange={(e) =>
                    handleFilterChange("clientName", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col> */}
            <Col xs={2}>
              <Form.Select
                className="py-3"
                value={filters.type}
                onChange={(e) => handleFilterChange("type", e.target.value)}
              >
                <option>Type</option>
                <option value="Direct Transfer">Direct Transfer</option>
                <option value="Destination Charge">Destination Charge</option>
              </Form.Select>
            </Col>
            <Col xs="auto" className="">
              <Button variant="light" onClick={clearFilters}>
                <FontAwesomeIcon icon={faRotateLeft} />
              </Button>
              <Button
                variant="success"
                className="mx-2"
                onClick={handleDownloadReports}
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
              <Button
                className=" px-3"
                variant="primary"
                onClick={() => filterPayments()}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} className="me-1" />
              </Button>
            </Col>
          </Row>
          {/* -------------------------------------------------- TABLE ----------------------------------------------- */}
          <Table striped bordered hover className="report">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Transaction id</th>
                <th>Sent To</th>
                <th>Tip</th>
                <th>Processing Fee</th>
                <th>Total</th>
                <th>Receipt</th>
              </tr>
            </thead>
            <tbody>
              {payments &&
                (filteredPayments && filteredPayments.length > 0
                  ? filteredPayments
                  : payments
                )
                  .slice()
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((payment, index) => (
                    <tr key={index}>
                      <td className="fw-bold">{index + 1}</td>
                      <td>
                        {new Date(payment.createdAt).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </td>

                      <td className="text-muted fst-italic">
                        {payment.paymentIntentId}
                      </td>
                      <td>
                        {payment.connectAccount != "Team Payment" ? (
                          <span className="fst-italic text-muted">
                            {payment.connectAccount}
                          </span>
                        ) : (
                          <span className="text-info">Direct Transfer</span>
                        )}
                      </td>
                      <td>${payment.tipAmount}</td>
                      <td className="text-center">${payment.processingFee}</td>
                      <td>${payment.amount}</td>
                      <td className="text-center">
                        <div onClick={() => handleDownloadReceipt(payment)}>
                          <FontAwesomeIcon
                            className="fs-5 text-success"
                            icon={faFileArrowDown}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default SuccessfullPaymentsAdmin;
