import {
  ADD_RATING_FAIL,
  ADD_RATING_SUCCESS,
  ADD_TEAM_MEMBER_FAIL,
  ADD_TEAM_MEMBER_REQUEST,
  ADD_TEAM_MEMBER_RESET,
  ADD_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_FAIL,
  DELETE_TEAM_MEMBER_REQUEST,
  DELETE_TEAM_MEMBER_RESET,
  DELETE_TEAM_MEMBER_SUCCESS,
  GET_VALET_TEAMS_FAIL,
  GET_VALET_TEAMS_REQUEST,
  GET_VALET_TEAMS_SUCCESS,
  MAID_ADD_TIP_FAIL,
  MAID_ADD_TIP_REQUEST,
  MAID_ADD_TIP_SUCCESS,
  MAID_CREATE_FAIL,
  MAID_CREATE_REQUEST,
  MAID_CREATE_RESET,
  MAID_CREATE_SUCCESS,
  MAID_DELETE_FAIL,
  MAID_DELETE_REQUEST,
  MAID_DELETE_SUCCESS,
  MAID_GET_ALL_FAIL,
  MAID_GET_ALL_REQUEST,
  MAID_GET_ALL_SUCCESS,
  MAID_GET_EARNINGS_FAIL,
  MAID_GET_EARNINGS_REQUEST,
  MAID_GET_EARNINGS_SUCCESS,
  MAID_UPDATE_FAIL,
  MAID_UPDATE_REQUEST,
  MAID_UPDATE_RESET,
  MAID_UPDATE_SUCCESS,
  PAY_MAID_FAIL,
  PAY_MAID_REQUEST,
  PAY_MAID_SUCCESS,
  STAFF_GET_ALL_FAIL,
  STAFF_GET_ALL_REQUEST,
  STAFF_GET_ALL_SUCCESS,
  VALET_TEAM_CREATE_FAIL,
  VALET_TEAM_CREATE_REQUEST,
  VALET_TEAM_CREATE_RESET,
  VALET_TEAM_CREATE_SUCCESS,
  VERIFY_PAYMENT_FAIL,
  VERIFY_PAYMENT_REQUEST,
  VERIFY_PAYMENT_SUCCESS,
} from "../Constants/maidConstants";

export const maidGetAllReducer = (state = { maids: [] }, action) => {
  switch (action.type) {
    case MAID_GET_ALL_REQUEST:
      return { loading: true, maids: [] };
    case MAID_GET_ALL_SUCCESS:
      return { loading: false, maids: action.payload };
    case MAID_GET_ALL_FAIL:
      return { loading: false, error: action.payload, maids: [] };
    case MAID_DELETE_SUCCESS:
      return {
        maids: state.maids.filter((p) => p._id !== action.payload._id),
        success: true,
      };
    case MAID_DELETE_FAIL:
      return { error: action.payload };
    default:
      return state;
  }
};
export const staffGetAllReducer = (state = { staff: [] }, action) => {
  switch (action.type) {
    case STAFF_GET_ALL_REQUEST:
      return { loading: true, staff: [] };
    case STAFF_GET_ALL_SUCCESS:
      return { loading: false, staff: action.payload };
    case STAFF_GET_ALL_FAIL:
      return { loading: false, error: action.payload, staff: [] };
    default:
      return state;
  }
};
export const maidCreateReducer = (state = { maid: null }, action) => {
  switch (action.type) {
    case MAID_CREATE_REQUEST:
      return { loading: true };
    case MAID_CREATE_SUCCESS:
      return { loading: false, success: true, maid: action.payload };
    case MAID_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case MAID_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const maidUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case MAID_UPDATE_REQUEST:
      return { loading: true };
    case MAID_UPDATE_SUCCESS:
      return { loading: false, success: true, maid: action.payload };
    case MAID_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case MAID_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const maidAddTipReducer = (state = {}, action) => {
  switch (action.type) {
    case MAID_ADD_TIP_REQUEST:
      return { loading: true };
    case MAID_ADD_TIP_SUCCESS:
      return { loading: false, success: true, tip: action.payload };
    case MAID_ADD_TIP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const maidGetEarningsReducer = (state = { earnings: [] }, action) => {
  switch (action.type) {
    case MAID_GET_EARNINGS_REQUEST:
      return { loading: true, earnings: [] };
    case MAID_GET_EARNINGS_SUCCESS:
      return { loading: false, earnings: action.payload };
    case MAID_GET_EARNINGS_FAIL:
      return { loading: false, error: action.payload, earnings: [] };
    default:
      return state;
  }
};

export const payMaidReducer = (state = {}, action) => {
  switch (action.type) {
    case PAY_MAID_REQUEST:
      return { loading: true, success: false, error: false };
    case PAY_MAID_SUCCESS:
      return {
        loading: false,
        success: true,
        error: false,
        transactionData: action.payload.transactionData,
      };
    case PAY_MAID_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};

export const addRatingReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_RATING_SUCCESS:
      return {
        maid: action.payload,
        error: null,
      };
    case ADD_RATING_FAIL:
      return {
        maid: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const valetTeamCreateReducer = (state = { valetTeam: null }, action) => {
  switch (action.type) {
    case VALET_TEAM_CREATE_REQUEST:
      return { loading: true };
    case VALET_TEAM_CREATE_SUCCESS:
      return { loading: false, success: true, valetTeam: action.payload };
    case VALET_TEAM_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case VALET_TEAM_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const valetTeamGetReducer = (state = { valetTeams: [] }, action) => {
  switch (action.type) {
    case GET_VALET_TEAMS_REQUEST:
      return { loading: true, valetTeams: [] };
    case GET_VALET_TEAMS_SUCCESS:
      return { loading: false, valetTeams: action.payload };
    case GET_VALET_TEAMS_FAIL:
      return { loading: false, error: action.payload, valetTeams: [] };
    default:
      return state;
  }
};

export const teamMemberAddReducer = (state = { teamMember: null }, action) => {
  switch (action.type) {
    case ADD_TEAM_MEMBER_REQUEST:
      return { loading: true };
    case ADD_TEAM_MEMBER_SUCCESS:
      return { loading: false, success: true, newTeam: action.payload };
    case ADD_TEAM_MEMBER_FAIL:
      return { loading: false, error: action.payload };
    case ADD_TEAM_MEMBER_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteTeamMemberReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_TEAM_MEMBER_REQUEST:
      return { loading: true };
    case DELETE_TEAM_MEMBER_SUCCESS:
      return { loading: false, success: true, newTeam: action.payload };
    case DELETE_TEAM_MEMBER_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_TEAM_MEMBER_RESET:
      return {};
    default:
      return state;
  }
};

export const verifyPaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_PAYMENT_REQUEST:
      return { loading: true };
    case VERIFY_PAYMENT_SUCCESS:
      return { loading: false, success: true, payouts: action.payload.payouts };
    case VERIFY_PAYMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
