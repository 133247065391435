import React from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import BellmanList from "./BellmanList";
import Reports from "./Reports";

const BellMan = ({ toAddMaid, toDeleteMaid, toDownload, isAdmin }) => {
  return (
    <>
      <Row className="m-0 py-4">
        <Col>
          <Tabs
            defaultActiveKey="bellman"
            id="justify-tab-example"
            className="border rounded-0 nav-pills text-light"
            justify
          >
            <Tab className="" eventKey="bellman" title="Bell Men">
              <BellmanList toAddMaid={toAddMaid} toDeleteMaid={toDeleteMaid} />
            </Tab>
            <Tab eventKey="reports" title="Reports">
              <Reports
                toDownload={toDownload}
                isAdmin={isAdmin}
                type={"bellman"}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default BellMan;
