import axios from "axios";
import {
  ADD_ROLE_FAILURE,
  ADD_ROLE_REQUEST,
  ADD_ROLE_SUCCESS,
  ADD_USER_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  DELETE_ROLE_FAIL,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  GET_ROLES_FAIL,
  GET_ROLES_REQUEST,
  GET_ROLES_SUCCESS,
  HOTEL_UPDATE_FAIL,
  HOTEL_UPDATE_REQUEST,
  HOTEL_UPDATE_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  ROLE_USER_DELETE_FAIL,
  ROLE_USER_DELETE_REQUEST,
  ROLE_USER_DELETE_SUCCESS,
  ROLE_USER_GET_ALL_FAIL,
  ROLE_USER_GET_ALL_REQUEST,
  ROLE_USER_GET_ALL_SUCCESS,
  ROLE_USER_UPDATE_FAIL,
  ROLE_USER_UPDATE_REQUEST,
  ROLE_USER_UPDATE_SUCCESS,
  UPDATE_ROLE_FAIL,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
} from "../Constants/userconstants";
import { BACKEND_URL } from "../../config.js";

export const userRegister =
  (
    companyName,
    partnerId,
    email,
    phoneNumber,
    location,
    // password,
    isAdmin,
    name,
    googleLink,
    docLink
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: USER_REGISTER_REQUEST });
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${BACKEND_URL}api/users/register`,
        {
          companyName,
          partnerId,
          email,
          phoneNumber,
          location,
          // password,
          isAdmin,
          name,
          googleLink,
          docLink,
        },
        config
      );
      // dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
      dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      dispatch({ type: USER_REGISTER_FAIL, payload: errorMessage });
    }
  };

export const userLogin = (email, password) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${BACKEND_URL}api/users/login`,
      { email, password },
      config
    );
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    let { userInfo } = getState().userLogin;
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: USER_LOGIN_FAIL, payload: errorMessage });
  }
};

export const userLogout = () => (dispatch) => {
  dispatch({ type: USER_LOGOUT });
  localStorage.clear();
};

export const listUsers = () => async (dispatch) => {
  try {
    dispatch({ type: USER_LIST_REQUEST });
    const { data } = await axios.get(`${BACKEND_URL}api/users`);
    dispatch({ type: USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: USER_LIST_FAIL, payload: errorMessage });
  }
};

export const getUserDetails = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`${BACKEND_URL}api/users/profile`, config);
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(userLogout());
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  try {
    dispatch({ type: USER_DELETE_REQUEST });
    // const config = {
    //   headers: {
    //
    //   },
    // };
    await axios.delete(`${BACKEND_URL}api/users/${userId}`);
    dispatch({ type: USER_DELETE_SUCCESS, payload: userId });
    // dispatch(listUsers());
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: USER_DELETE_FAIL, payload: errorMessage });
  }
};

export const getRoles = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ROLES_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`${BACKEND_URL}api/users/role`, config);
    dispatch({ type: GET_ROLES_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: GET_ROLES_FAIL, payload: errorMessage });
  }
};

export const addRole = (roleData) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_ROLE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const response = await axios.post(
      `${BACKEND_URL}api/users/role`,
      roleData,
      config
    );
    dispatch({ type: ADD_ROLE_SUCCESS, payload: response.data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: ADD_ROLE_FAILURE, payload: errorMessage });
  }
};

export const deleteRole = (roleId) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_ROLE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`${BACKEND_URL}api/users/role`, {
      data: { roleId },
      ...config,
    });
    dispatch({ type: DELETE_ROLE_SUCCESS, payload: roleId });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: DELETE_ROLE_FAIL, payload: errorMessage });
  }
};

export const addUser = (userData) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_USER_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `${BACKEND_URL}api/users/addUser`,
      userData,
      config
    );

    dispatch({ type: ADD_USER_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: ADD_USER_FAILURE, payload: errorMessage });
  }
};

export const getRoleUsers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ROLE_USER_GET_ALL_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${BACKEND_URL}api/users/roleUsers`,
      config
    );
    dispatch({ type: ROLE_USER_GET_ALL_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: ROLE_USER_GET_ALL_FAIL, payload: errorMessage });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_PROFILE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `${BACKEND_URL}api/users/profile`,
      user,
      config
    );
    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS });
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: errorMessage,
    });
  }
};

export const updateHotel =
  (
    id,
    companyName,
    accountType,
    name,
    email,
    password,
    phoneNumber,
    location,
    // accountStatus,
    services,
    qrCodes,
    valetLocations,
    sendEmail
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: HOTEL_UPDATE_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${BACKEND_URL}api/users/changeDetails`,
        {
          id,
          companyName,
          accountType,
          name,
          email,
          password,
          phoneNumber,
          location,
          // accountStatus,
          services,
          qrCodes,
          valetLocations,
          sendEmail,
        },
        config
      );
      dispatch({ type: HOTEL_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      dispatch({ type: HOTEL_UPDATE_FAIL, payload: errorMessage });
    }
  };

export const deleteRoleUser = (userId) => async (dispatch) => {
  try {
    dispatch({ type: ROLE_USER_DELETE_REQUEST });

    await axios.delete(`${BACKEND_URL}api/users/roleUsers/${userId}`);

    dispatch({ type: ROLE_USER_DELETE_SUCCESS, payload: userId });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: ROLE_USER_DELETE_FAIL, payload: errorMessage });
  }
};

export const updateRole = (roleId, updatedRoleData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ROLE_REQUEST });

    const { data } = await axios.put(
      `${BACKEND_URL}api/users/role/${roleId}`,
      updatedRoleData
    );
    dispatch({
      type: UPDATE_ROLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ROLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updateRoleUser = (userId, userData) => async (dispatch) => {
  try {
    dispatch({ type: ROLE_USER_UPDATE_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      `${BACKEND_URL}api/users/roleUsers/${userId}`,
      userData,
      config
    );
    dispatch({ type: ROLE_USER_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ROLE_USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${BACKEND_URL}api/users/reset-password`,
      { email },
      config
    );
    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAILURE,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};
