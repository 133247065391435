import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { dataURItoBlob, saveAs } from "../controllers/download";

const ReportDownload = () => {
  const [downloading, setDownloading] = useState(false);
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const [selectedMaid, setSelectedMaid] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maids, setMaids] = useState([]);
  const [airbnbReport, setAirbnbReport] = useState(false);

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const maids = JSON.parse(queryParams.get("maids"));
  // const selectedMaid = JSON.parse(queryParams.get("selectedMaid"));
  // const startDate = queryParams.get("startDate");
  // const endDate = queryParams.get("endDate");
  // const airbnbReport = queryParams.get("airbnbReport");
  // const navigate = useNavigate();

  const calculateMaidStats = (maid) => {
    const totalEarnings = maid.tips.reduce((acc, tip) => acc + tip.amount, 0);
    const totalReviews = maid.tips.reduce(
      (acc, tip) => acc + tip.review.length,
      0
    );
    return { totalEarnings, totalReviews };
  };

  const handleDownload = () => {
    setDownloading(true);
    const table = document.querySelector(".report");
    const data = [];
    const headers = [];
    table.querySelectorAll("thead th").forEach((th) => {
      headers.push(th.textContent.trim());
    });
    data.push(headers);
    table.querySelectorAll("tbody tr").forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent.trim());
      });
      data.push(rowData);
    });
    // Create a worksheet and add the data
    const ws = XLSX.utils.aoa_to_sheet(data);
    // Create a workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    // Save the Excel file
    XLSX.writeFile(wb, "report.xlsx");
    setDownloading(false);
  };

  useEffect(() => {
    const airbnbReport = JSON.parse(localStorage.getItem("airbnbReport"));
    const maids = JSON.parse(localStorage.getItem("maids"));
    const selectedMaid = JSON.parse(localStorage.getItem("selectedMaid"));
    const startDate = localStorage.getItem("startDate");
    const endDate = localStorage.getItem("endDate");
    setAirbnbReport(airbnbReport);
    setMaids(maids);
    setSelectedMaid(selectedMaid);
    setStartDate(startDate);
    setEndDate(endDate);
    // Clear local storage when tab is closed
    const handleTabClose = () => {
      localStorage.removeItem("airbnbReport");
      localStorage.removeItem("selectedMaid");
      localStorage.removeItem("startDate");
      localStorage.removeItem("endDate");
      localStorage.removeItem("maids");
    };

    document.addEventListener("visibilitychange", handleTabClose);

    return () => {
      document.removeEventListener("visibilitychange", handleTabClose);
    };
  }, []);
  return (
    <>
      <Row className="m-0 p-5">
        <Row>
          <Col sm="auto" className="ms-auto">
            <Button variant="success" className="mx-2" onClick={handleDownload}>
              <FontAwesomeIcon className="pe-2" icon={faDownload} />
              {downloading ? "downloading.." : "Download"}
            </Button>
          </Col>
        </Row>
        <Row className="my-5 report">
          {selectedMaid != undefined ? (
            <>
              <h5 className="text-center">
                {airbnbReport
                  ? selectedMaid.serviceName
                  : selectedMaid.firstName}
                's Earning
              </h5>
              {startDate != (undefined || "") &&
              endDate != (undefined || "") ? (
                <>
                  <span className="text-muted text-center mb-2">
                    From: <span className="mx-2">{startDate}</span> to:
                    <span className=" mx-2">{endDate}</span>
                  </span>
                </>
              ) : (
                <></>
              )}
              <Table bordered hover className="">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Tip</th>
                    <th>Reviews</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedMaid.tips.map((tip, index) => (
                    <tr key={tip._id}>
                      <td>
                        {new Date(tip.date).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </td>
                      <td>${tip.amount}</td>
                      <td>{tip.review}</td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td className="text-start">
                      Total = {""}
                      <b>
                        $
                        {selectedMaid.tips.reduce(
                          (acc, tip) => acc + tip.amount,
                          0
                        )}
                      </b>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : (
            <Table bordered hover className="">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{airbnbReport ? "Unit name " : "Name"}</th>
                  {!airbnbReport && <th>Service Name</th>}
                  <th>Earnings</th>
                  <th>Reviews</th>
                </tr>
              </thead>
              <tbody>
                {maids &&
                  maids.length > 0 &&
                  maids.map((maid, index) => (
                    <tr key={maid._id}>
                      <td>{index + 1}</td>
                      {airbnbReport ? (
                        <td>{maid.serviceName}</td>
                      ) : (
                        <td>{`${maid.firstName} ${maid.lastName}`}</td>
                      )}
                      {!airbnbReport && <td>{maid.serviceName}</td>}
                      <td>${calculateMaidStats(maid).totalEarnings}</td>
                      <td>{calculateMaidStats(maid).totalReviews}</td>
                    </tr>
                  ))}
                <tr>
                  {!airbnbReport && <td></td>}
                  <td></td>
                  <td></td>
                  <td className="text-start">
                    Total = {""}
                    <b>
                      $
                      {maids.reduce(
                        (acc, maid) =>
                          acc + calculateMaidStats(maid).totalEarnings,
                        0
                      )}
                    </b>
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </Row>
      </Row>
    </>
  );
};

export default ReportDownload;
