import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "./Loader";

const RequireAdminAuth = ({ children }) => {
  const { loading, userInfo } = useSelector((state) => state.userLogin);

  if (loading) {
    return <Loader />;
  }
  if (!userInfo) {
    return <Navigate to="/login" />;
  } else if (
    (userInfo && userInfo.isSuperAdmin == true) ||
    userInfo.isPartner == true
  ) {
    return children;
  } else {
    alert("Not authorized. Please log in.");
  }
};

export default RequireAdminAuth;
