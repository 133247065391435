export const calculateAverageRating = (ratings) => {
  if (ratings && ratings.length > 0) {
    const sum = ratings.reduce((acc, rating) => acc + rating, 0);
    return sum / ratings.length;
  }
  return 0;
};

export const generateServiceName = (firstName, lastName, allMaids) => {
  let serviceName =
    firstName.charAt(0).toUpperCase() +
    firstName.slice(1).toLowerCase().replace(/\s/g, "");

  const potentialServiceName =
    serviceName + " " + lastName.charAt(0).toUpperCase();
  // Check if the generated service name already exists
  const isDuplicateService = allMaids.some(
    (maid) =>
      maid.serviceName.toLowerCase().replace(/\s/g, "") ===
        serviceName.toLowerCase().replace(/\s/g, "") ||
      maid.serviceName.toLowerCase().replace(/\s/g, "") ===
        potentialServiceName.toLowerCase().replace(/\s/g, "")
  );
  // If the generated service name already exists, use the full potential service name
  if (isDuplicateService) {
    serviceName = potentialServiceName;
  }
  return serviceName;
};
