import {
  faCheck,
  faDownload,
  faMagnifyingGlass,
  faRotateLeft,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { format } from "date-fns";
import * as XLSX from "xlsx";
import { Col, FloatingLabel, Row, Table, Form, Button } from "react-bootstrap";

const TeamPayouts = ({ payments }) => {
  const [filteredPayments, setFilteredPayments] = useState();
  const [filters, setFilters] = useState({
    date: "",
    transactionId: "",
    clientName: "",
  });
  const options = { year: "numeric", month: "numeric", day: "numeric" };

  const handleFilterChange = (fieldName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: value,
    }));
  };
  const filterPayments = () => {
    const filteredResults = payments.filter((payment) => {
      const dateMatch =
        !filters.date ||
        format(new Date(payment.createdAt), "yyyy-MM-dd") ===
          format(new Date(filters.date), "yyyy-MM-dd");

      const transactionIdMatch =
        !filters.transactionId ||
        payment.paymentIntentId.includes(
          filters.transactionId.replace(/\s/g, "")
        );

      const clientNameMatch =
        !filters.clientName ||
        (payment.clientName &&
          payment.clientName
            .toLowerCase()
            .replace(/\s/g, "")
            .includes(filters.clientName.toLowerCase().replace(/\s/g, "")));

      return dateMatch && transactionIdMatch && clientNameMatch;
    });
    setFilteredPayments(filteredResults);
    // .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  };
  const clearFilters = () => {
    setFilters({
      date: "",
      transactionId: "",
      clientName: "",
      type: "",
    });
    setFilteredPayments(null);
  };
  const handleDownloadReports = () => {
    const table = document.querySelector(".team-payouts");
    const data = [];
    const headers = [];
    table.querySelectorAll("thead th").forEach((th) => {
      headers.push(th.textContent.trim());
    });
    data.push(headers);
    table.querySelectorAll("tbody tr").forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell, index) => {
        rowData.push(cell.textContent.trim());
      });
      data.push(rowData);
    });
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, "tipmaid-team-payouts.xlsx");
  };
  return (
    <>
      <Row className="m-0 mt-4">
        <Col className="p-0">
          <Row className=" mb-4 align-items-center justify-content-center">
            <Col xs={3}>
              <FloatingLabel label="Date">
                <Form.Control
                  type="date"
                  placeholder="Date"
                  value={filters.date}
                  onChange={(e) => handleFilterChange("date", e.target.value)}
                />
              </FloatingLabel>
            </Col>
            <Col xs={3}>
              <FloatingLabel label="Transaction Id">
                <Form.Control
                  type="text"
                  placeholder="Transaction Id"
                  value={filters.transactionId}
                  onChange={(e) =>
                    handleFilterChange("transactionId", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            <Col xs={2}>
              <FloatingLabel label="Client Name">
                <Form.Control
                  type="text"
                  placeholder="Client Name"
                  value={filters.clientName}
                  onChange={(e) =>
                    handleFilterChange("clientName", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            <Col xs="auto" className="">
              <Button variant="light" onClick={clearFilters}>
                <FontAwesomeIcon icon={faRotateLeft} />
              </Button>
              <Button
                variant="success"
                className="mx-2"
                onClick={handleDownloadReports}
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
              <Button
                className=" px-3"
                variant="primary"
                onClick={() => filterPayments()}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} className="me-1" />
              </Button>
            </Col>
          </Row>

          <Table striped bordered hover className="team-payouts">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Team Id</th>
                {/* <th>Transfer id</th>
                <th>Sent To</th> */}
                <th>Client Name</th>
                <th>Tip</th>
              </tr>
            </thead>
            <tbody>
              {payments &&
                (filteredPayments && filteredPayments.length > 0
                  ? filteredPayments
                  : payments
                )
                  .slice()
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((payment, index) => (
                    <tr key={index}>
                      <td className="fw-bold">{index + 1}</td>
                      <td>
                        {new Date(payment.createdAt).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </td>
                      <td className="text-muted fst-italic">
                        {payment.teamId}
                      </td>
                      {/* <td className="text-muted fst-italic">
                        {payment.paymentIntentId}
                      </td>
                      <td className="text-muted fst-italic">
                        {payment.connectAccount}
                      </td> */}
                      <td>{payment.clientName}</td>
                      <td>${payment.amount}</td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default TeamPayouts;
