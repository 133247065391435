// export const BACKEND_URL = "http://localhost:8000/";
export const BACKEND_URL = "/";

// Chatbot link
// http://localhost:3000/bot?ref=airbnb&hotel=6605b5f932d4dad145d0ed2b

// For normal client
// http://localhost:3000/payment?account=acct_1PbTfaHKfDMm1K1D&maid=669044a197ba759c1f062e0a&hotel=6690415d97ba759c1f062b6a&amount=5&review=Good

// For testing biz dev partners
// http://localhost:3000/payment?maid=acct_1OlxEYH4wk1diJVR&hotel=65d4dea0204b2f7895688bea&amount=5&review=Good4

// For Valet team tips
// http://localhost:3000/teamtip?hotel=6690415d97ba759c1f062b6a
