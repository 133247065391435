import {
  FAILED_PAYMENT_GET_ALL_FAIL,
  FAILED_PAYMENT_GET_ALL_REQUEST,
  FAILED_PAYMENT_GET_ALL_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  PAYMENT_GET_ALL_FAIL,
  PAYMENT_GET_ALL_REQUEST,
  PAYMENT_GET_ALL_SUCCESS,
} from "../Constants/paymentConstants";

export const paymentGetAllReducer = (state = { payments: [] }, action) => {
  switch (action.type) {
    case PAYMENT_GET_ALL_REQUEST:
      return { loading: true, payments: [] };
    case PAYMENT_GET_ALL_SUCCESS:
      return { loading: false, payments: action.payload };
    case PAYMENT_GET_ALL_FAIL:
      return { loading: false, error: action.payload, payments: [] };
    default:
      return state;
  }
};

export const failedPaymentGetAllReducer = (
  state = { failedPayments: [] },
  action
) => {
  switch (action.type) {
    case FAILED_PAYMENT_GET_ALL_REQUEST:
      return { loading: true, failedPayments: [] };
    case FAILED_PAYMENT_GET_ALL_SUCCESS:
      return { loading: false, failedPayments: action.payload };
    case FAILED_PAYMENT_GET_ALL_FAIL:
      return { loading: false, error: action.payload, failedPayments: [] };
    default:
      return state;
  }
};

export const getTransactionsReducer = (
  state = { transactions: [] },
  action
) => {
  switch (action.type) {
    case GET_TRANSACTIONS_REQUEST:
      return { loading: true, transactions: [] };
    case GET_TRANSACTIONS_SUCCESS:
      return { loading: false, transactions: action.payload };
    case GET_TRANSACTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
