import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userRegister as register } from "../redux/Actions/userAction";
import Message from "../components/Message";
import Loader from "../components/Loader";

const Register = ({ setUserListUpdate, setCreatedPassword, userId }) => {
  const [validated, setValidated] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [name, setName] = useState("");
  const [googleLink, setGoogleLink] = useState("");
  const [docLink, setDocLink] = useState("");
  const [accountStatus, setAccountStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [location, setLocation] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(true);
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();
  setUserListUpdate(false);
  // const redirect =
  //   query.get("redirect") === null ? "/dashboard" : query.get("redirect");

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, success } = userRegister;

  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (password !== confirmPassword && form.checkValidity() === true) {
      setMessage("Password do not match");
    } else if (password == confirmPassword && form.checkValidity() === true) {
      setMessage(null);
      dispatch(
        register(
          companyName,
          userId,
          email,
          phoneNumber,
          location,
          // password,
          isAdmin,
          name,
          googleLink,
          docLink
        )
      );
      setUserListUpdate(true);
      // setCreatedPassword(password);
    }
  };
  return (
    <>
      <Row className="px-5 my-3">
        <Col>
          {error ? <Message>{error.message}</Message> : null}
          {message ? <Message variant="danger">{message}</Message> : null}
          {loading ? <Loader /> : null}
          {success ? (
            <Alert variant="success" className="text-center">
              Added successfully !
            </Alert>
          ) : (
            <Form noValidate validated={validated} onSubmit={submitHandler}>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    ></Form.Control>
                    {/* <Form.Control.Feedback type="invalid">
                      This field is required*
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Contact Person Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      pattern="[A-Za-z ]+"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    ></Form.Control>
                    {/* <Form.Control.Feedback type="invalid">
                      This field is required*
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Enter a valid Email*
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  required
                  type="tel"
                  pattern="^[0-9+ ]+$"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Enter a valid Phone Number*
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required*
                </Form.Control.Feedback>
              </Form.Group>
              {/* <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required*
                </Form.Control.Feedback>
              </Form.Group> */}
              {/* <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required*
                </Form.Control.Feedback>
              </Form.Group> */}
              {/* <Form.Group>
                <Form.Label>Google Form Link</Form.Label>
                <Form.Control
                  type="text"
                  value={googleLink}
                  onChange={(e) => {
                    setGoogleLink(e.target.value);
                  }}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required*
                </Form.Control.Feedback>
              </Form.Group> */}
              <Form.Group>
                <Form.Label>PandaDoc Document Link</Form.Label>
                <Form.Control
                  type="text"
                  value={docLink}
                  onChange={(e) => {
                    setDocLink(e.target.value);
                  }}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required*
                </Form.Control.Feedback>
              </Form.Group>
              {/* <Form.Check
                // disabled
                className="my-3"
                type="switch"
                id="admin-switch"
                label="Admin"
                checked={isAdmin}
                onChange={() => setIsAdmin(!isAdmin)}
              /> */}
              <Row>
                <Col sm="auto" className="ms-auto mt-4">
                  <Button type="submit" className="btn-success px-5">
                    Register
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Register;
