import logo from "./logo.svg";
import { Route, Routes } from "react-router-dom";
import { BACKEND_URL } from "./config.js";
import "./bootstrap.min.css";
import Login from "./screens/Login";
import { Container } from "react-bootstrap";
import Register from "./screens/Register";
import Home from "./screens/Home";
import Dashboard from "./screens/Dashboard";
import AddTip from "./components/AddTip";
import Payment from "./screens/Payment";
import StripeContainer from "./components/StripeContainer";
import SuperAdmin from "./screens/SuperAdmin.jsx";
import RequireAuth from "./components/RequireAuth.jsx";
import RequireAdminAuth from "./components/RequireAdminAuth.jsx";
import TermsConditions from "./components/Terms&Conditions.jsx";
import PrivacyPolicy from "./components/PrivacyPolicy.jsx";
import Rating from "./components/Rating.jsx";
import Bot from "./screens/Bot.jsx";
import ReportDownload from "./components/ReportDownload.jsx";
import TeamTip from "./screens/TeamTip.jsx";
import Terms from "./screens/Terms.jsx";
import ReceiptDownloadPage from "./components/ReceiptDownloadPage.jsx";
import ResetPassword from "./components/ResetPassword.jsx";

function App() {
  return (
    <>
      <main>
        <Container fluid className="vh-100 p-0 m-0 ">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/download" element={<ReportDownload />} />
            <Route path="/receipt" element={<ReceiptDownloadPage />} />
            <Route path="/teamtip" element={<TeamTip />} />
            {/* <Route path="/bot" element={<Bot />} /> */}
            <Route path="/rating" element={<Rating />} />
            <Route path="/tipmaidTerms" element={<TermsConditions />}></Route>
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
            <Route
              path="/super-admin"
              element={
                <RequireAdminAuth>
                  <SuperAdmin />
                </RequireAdminAuth>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            {/* <Route path="/register" element={<Register />} /> */}
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            {/* <Route path="/tip-test" element={<AddTip />} /> */}
            <Route path="/payment" element={<StripeContainer />} />
          </Routes>
        </Container>
      </main>
    </>
  );
}

export default App;
