import React, { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SuccessfullPayments from "./SuccessfullPayments";
import TeamPayouts from "./TeamPayouts";
import FailedPayments from "./FailedPayments";
import {
  getAllFailedPayments,
  getAllPayments,
} from "../redux/Actions/paymentAction";
import SuccessfullPaymentsAdmin from "./SuccessfullPaymentsAdmin";
import FailedPaymentsAdmin from "./FailedPaymentsAdmin";
const TransactionReportAdmin = ({ userId }) => {
  const [teamTipPayments, setTeamTipPayments] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [failedTransactions, setFailedTransactions] = useState([]);
  const { payments, loading } = useSelector((state) => state.allPayments);
  const { failedPayments, loading: failedPaymentsLoading } = useSelector(
    (state) => state.failedPayments
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPayments());
    dispatch(getAllFailedPayments());
  }, [dispatch]);

  useEffect(() => {
    if (payments) {
      const filteredTeamTipPayments = payments.filter(
        (payment) => payment.teamTip
      );
      const allTransactions = payments.filter(
        (payment) => payment.teamTip !== true
      );
      const successfulTransactions = allTransactions.filter(
        (transaction) => transaction.clientId === userId
      );
      const successfulTeamPayments = filteredTeamTipPayments.filter(
        (transaction) => transaction.clientId === userId
      );
      const unsuccessfulTransactions = failedPayments.filter(
        (transaction) => transaction.clientId === userId
      );
      setTransactions(successfulTransactions);
      setTeamTipPayments(successfulTeamPayments);
      setFailedTransactions(unsuccessfulTransactions);
    }
  }, [payments]);
  return (
    <>
      <Row className="m-0 p-4">
        <Col>
          <Tabs
            defaultActiveKey="payments"
            id="justify-tab-example"
            className="border rounded-0 nav-pills text-light"
            justify
          >
            <Tab
              className=""
              eventKey="payments"
              title="Successful Transactions"
            >
              <SuccessfullPaymentsAdmin
                payments={transactions && transactions}
              />
            </Tab>
            {/* <Tab eventKey="teamPayout" title="Team Tip Payouts">
              <TeamPayouts payments={teamTipPayments} />
            </Tab> */}
            <Tab eventKey="failedPayments" title="Failed Transactions">
              <FailedPaymentsAdmin payments={failedTransactions} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default TransactionReportAdmin;
