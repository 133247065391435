import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "./Loader";

const RequireAuth = ({ children }) => {
  const { loading, userInfo } = useSelector((state) => state.userLogin);

  if (loading) {
    return <Loader />;
  }
  if (!userInfo) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
};

export default RequireAuth;
