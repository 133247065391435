import React, { useRef } from "react";
import { Button, Form } from "react-bootstrap";

const FileInputField = ({ uploaded, onChange, accept }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    onChange(e, "forBellmen");
  };

  return (
    <div>
      {/* <Form.Label className="text-muted">{label}</Form.Label> */}
      <div>
        <input
          type="file"
          accept={accept}
          onChange={handleFileChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <Button
          className="rounded-5"
          size="sm"
          onClick={handleButtonClick}
          variant={uploaded ? "outline-success" : "outline-primary"}
        >
          {uploaded ? "Update File" : "Choose File"}
        </Button>
      </div>
    </div>
  );
};

export default FileInputField;
