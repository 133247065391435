import React from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import Reports from "./Reports";
import AttendantsList from "./AttendantsList";

const Attendants = ({ toAddMaid, toDeleteMaid, toDownload, isAdmin }) => {
  return (
    <>
      <Row className="m-0 py-4">
        <Col>
          <Tabs
            defaultActiveKey="attendants"
            id="justify-tab-example"
            className="border rounded-0 nav-pills text-light"
            justify
          >
            <Tab className="" eventKey="attendants" title="Attendants">
              <AttendantsList
                toAddMaid={toAddMaid}
                toDeleteMaid={toDeleteMaid}
              />
            </Tab>
            <Tab eventKey="reports" title="Reports">
              <Reports
                toDownload={toDownload}
                isAdmin={isAdmin}
                type={"maid"}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default Attendants;
