import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import timaidLogo from "../images/tipmaid-newlogo.png";
import html2pdf from "html2pdf.js";
import { Card, Col, Row } from "react-bootstrap";

const Receipt = ({ transactionData }) => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const cardRef = useRef(null);

  const handleDownloadReceipt = () => {
    const cardElement = cardRef.current;

    if (cardElement) {
      cardElement.style.visibility = "visible";
      cardElement.style.position = "relative";
      cardElement.style.position = "";
      cardElement.style.left = "";
      cardElement.style.top = "";

      const newWindow = window.open("", "_blank");
      if (newWindow) {
        const transactionDataString = encodeURIComponent(
          JSON.stringify(transactionData)
        );
        newWindow.location.href = `/receipt?data=${transactionDataString}`;
      } else {
        console.error(
          "Failed to open a new window. Ensure that pop-ups are allowed."
        );
      }
    }
  };
  return (
    <>
      <Row className="justify-content-center my-2">
        <Col
          xs="auto"
          className="text-decoration-underline fst-italic text-info"
        >
          <span style={{ cursor: "pointer" }} onClick={handleDownloadReceipt}>
            Download Receipt
            <FontAwesomeIcon icon={faDownload} className="ms-1" />
          </span>
        </Col>
      </Row>
      <Row
        className="justify-content-center"
        ref={cardRef}
        style={{
          width: "23rem",
          position: "absolute",
          left: "-9999px",
          top: "-9999px",
        }}
      >
        <Col xs="auto">
          {transactionData && (
            <Card className="rounded-4 px-2 pt-3">
              <Card.Body>
                <Row className="justify-content-center px-3">
                  <Col xs="auto" className="text-center">
                    <Card.Img variant="top" src={timaidLogo} className="w-75" />
                  </Col>
                  <hr className="mt-3 text-secondary" />
                </Row>
                <Row className="my-1">
                  <Card.Title className="text-center text-primary fw-bold">
                    {transactionData.clientName}
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-muted text-center">
                    {transactionData.clientLocation}
                  </Card.Subtitle>
                </Row>
                <Row>
                  <Row>
                    <Col xs={6} className="fw-bold">
                      Date :
                    </Col>
                    <Col xs={6} className="text-muted">
                      {new Date(transactionData.createdAt).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className="fw-bold">
                      Payment ID :
                    </Col>
                    <Col xs={6} className="fst-italic text-muted">
                      {transactionData.paymentIntentId}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className="fw-bold">
                      Recipient ID :
                    </Col>
                    <Col xs={6} className="fst-italic text-muted">
                      {transactionData.connectAccount}
                    </Col>
                  </Row>
                </Row>
                <Row className="mt-4">
                  <hr />
                  <Row>
                    <Col xs={6} className="fw-bold">
                      Tip :
                    </Col>
                    <Col xs={6}>${transactionData.tipAmount}</Col>
                  </Row>
                  <Row>
                    <Col xs={6} className="fw-bold">
                      Service Fee :
                    </Col>
                    <Col xs={6}>${transactionData.processingFee}</Col>
                  </Row>
                  <hr className="mt-3 mb-2" />
                  <Row>
                    <Col xs={6} className="fw-bold">
                      Amount Paid :
                    </Col>
                    <Col xs={6} className="text-success">
                      ${transactionData.amount}
                    </Col>
                  </Row>
                </Row>
              </Card.Body>
              <Row className="mt-3 mb-2">
                <Col className="fs-7 text-muted text-center">
                  www.tipmaid.io
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Receipt;
