import axios from "axios";
import {
  PARTNER_REGISTER_FAIL,
  PARTNER_REGISTER_REQUEST,
  PARTNER_REGISTER_SUCCESS,
} from "../Constants/bizDevConstants";
import { BACKEND_URL } from "../../config";

export const partnerRegister =
  (fullName, email, location, password, isPartner, commision, accountType) =>
  async (dispatch) => {
    try {
      dispatch({ type: PARTNER_REGISTER_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${BACKEND_URL}api/users/partner-register`,
        {
          fullName,
          email,
          location,
          password,
          isPartner,
          commision,
          accountType,
        },
        config
      );
      dispatch({ type: PARTNER_REGISTER_SUCCESS, payload: data });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      dispatch({
        type: PARTNER_REGISTER_FAIL,
        payload: errorMessage,
      });
    }
  };
