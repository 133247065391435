export const PAYMENT_GET_ALL_REQUEST = "PAYMENT_GET_ALL_REQUEST";
export const PAYMENT_GET_ALL_SUCCESS = "PAYMENT_GET_ALL_SUCCESS";
export const PAYMENT_GET_ALL_FAIL = "PAYMENT_GET_ALL_FAIL";

export const FAILED_PAYMENT_GET_ALL_REQUEST = "FAILED_PAYMENT_GET_ALL_REQUEST";
export const FAILED_PAYMENT_GET_ALL_SUCCESS = "FAILED_PAYMENT_GET_ALL_SUCCESS";
export const FAILED_PAYMENT_GET_ALL_FAIL = "FAILED_PAYMENT_GET_ALL_FAIL";

export const GET_TRANSACTIONS_REQUEST = "GET_TRANSACTIONS_REQUEST";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL";
