import { faAirbnb } from "@fortawesome/free-brands-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import {
  faCircle,
  faCircleUser,
  faCircleXmark,
  faExclamation,
  faHouse,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Badge,
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";

const ClientDetail = ({ staff, clients }) => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const [show, setShow] = useState(false);
  const [members, setMembers] = useState([]);

  const handleClose = () => {
    setShow(false);
    setMembers([]);
  };
  const handleShow = () => setShow(true);
  const filterStaff = (clientId, service) => {
    return staff.filter(
      (member) =>
        member.hotel === clientId &&
        member.type === service &&
        member.chargesStatus === true
    );
  };
  const getStaff = (clientId, service) => {
    return staff.filter(
      (member) => member.hotel === clientId && member.type === service
    );
  };
  const getClientStatus = (client) => {
    if (
      !client.services.airbnb &&
      !client.services.maids &&
      !client.services.valets &&
      !client.services.bellmen
    ) {
      return false;
    } else {
      return true;
    }
  };
  const styles = {
    shadow: {
      boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
  };
  return (
    <>
      {/* ----------------------------------------------- LIST --------------------------------------------------- */}
      <Row className="p-4 m-0 my-3 ">
        <Col className="p-3 py-4 rounded-4" style={styles.shadow}>
          <Row>
            <Col sm="auto" className="fw-bold">
              #
            </Col>
            <Col sm={1} className="fw-bold">
              Date added
            </Col>
            <Col sm={2} className="fw-bold">
              Client name
            </Col>
            <Col sm={1} className="fw-bold text-center">
              Stripe
            </Col>
            <Col sm={2} className="fw-bold">
              Contact person
            </Col>
            <Col sm={4} className="fw-bold text-center">
              Services
            </Col>
            <Col xs={1} className="text-center fw-bold ms-auto">
              Status
            </Col>
          </Row>
          <hr className="mt-3 mb-1" />
          {clients.map((client, index) => {
            return (
              <>
                <Row className="align-items-center">
                  <Col sm="auto" className="fw-bold">
                    {index + 1}.
                  </Col>
                  <Col sm={1}>
                    {new Date(client.createdAt).toLocaleDateString(
                      "en-US",
                      options
                    )}
                  </Col>
                  <Col sm={2} className="fw-bold text-primary p-0">
                    {client.companyName}
                  </Col>
                  <Col sm={1} className="text-center p-0">
                    {client.chargesStatus ? (
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="text-success me-1"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faExclamation}
                        className="text-danger me-2"
                      />
                    )}
                  </Col>
                  <Col sm={2} className="text-muted p-0">
                    {client.name}
                  </Col>
                  <Col sm={4} className="p-0">
                    <Row className="justify-content-center p-0">
                      <Col xs={3} className="p-0">
                        {client.services.airbnb ? (
                          <>
                            <Badge bg="success">Airbnb units</Badge>
                            <FontAwesomeIcon
                              icon={faAirbnb}
                              className="mx-1 text-success hover-effect"
                              // onClick={() => {
                              //   setMembers(getStaff(client._id, "maid"));
                              //   setShow(true);
                              // }}
                            />
                            <span className="text-success fs-7">
                              {getStaff(client._id, "airbnb").length}{" "}
                            </span>
                            {/* {filterStaff(client._id, "maid").length}/
                            {getStaff(client._id, "maid").length} */}
                          </>
                        ) : (
                          <Badge bg="secondary">Airbnb units</Badge>
                        )}
                      </Col>
                      <Col xs={3} className="p-0">
                        {client.services.maids ? (
                          <>
                            <Badge bg="success">Attendants</Badge>
                            <FontAwesomeIcon
                              icon={faCircleUser}
                              className="mx-1 text-success hover-effect"
                              // onClick={() => {
                              //   setMembers(getStaff(client._id, "maid"));
                              //   setShow(true);
                              // }}
                            />
                            <span className="text-success fs-7">
                              {getStaff(client._id, "maid").length}{" "}
                            </span>
                            {/* {filterStaff(client._id, "maid").length}/
                            {getStaff(client._id, "maid").length} */}
                          </>
                        ) : (
                          <Badge bg="secondary">Attendants</Badge>
                        )}
                      </Col>
                      <Col xs={3} className="p-0">
                        {client.services.valets ? (
                          <>
                            <Badge bg="success" className="px-3">
                              Valet
                            </Badge>

                            <FontAwesomeIcon
                              icon={faCircleUser}
                              className="mx-1 text-success hover-effect"
                              // onClick={() => {
                              //   setMembers(getStaff(client._id, "valet"));
                              //   setShow(true);
                              // }}
                            />
                            <span className="text-success fs-7">
                              {getStaff(client._id, "valet").length}{" "}
                            </span>
                            {/* {filterStaff(client._id, "valet").length}/
                            {getStaff(client._id, "valet").length} */}
                          </>
                        ) : (
                          <Badge bg="secondary" className="px-3">
                            Valet
                          </Badge>
                        )}
                      </Col>
                      <Col xs={3} className="p-0">
                        {client.services.bellmen ? (
                          <>
                            <Badge bg="success">Bellmen</Badge>

                            <FontAwesomeIcon
                              icon={faCircleUser}
                              className="mx-1 text-success hover-effect"
                              // onClick={() => {
                              //   setMembers(getStaff(client._id, "bellman"));
                              //   setShow(true);
                              // }}
                            />

                            <span className="text-success fs-7">
                              {getStaff(client._id, "bellman").length}
                            </span>
                            {/* {filterStaff(client._id, "bellman").length}/
                            {getStaff(client._id, "bellman").length} */}
                          </>
                        ) : (
                          <Badge bg="secondary">Bellmen</Badge>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={1} className="text-center ms-auto p-0">
                    {getClientStatus(client) ? (
                      <span className="text-success">Active</span>
                    ) : (
                      <span className="text-warning">Pending</span>
                    )}
                  </Col>
                </Row>
                <hr className="my-1" />
              </>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

export default ClientDetail;
