import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { userLogin } from "../redux/Actions/userAction";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = login;
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(userLogin(email, password));
  };
  useEffect(() => {
    if (userInfo) {
      const redirectPath =
        userInfo.isSuperAdmin == true || userInfo.isPartner == true
          ? "/super-admin"
          : userInfo.isAdmin == true
          ? "/dashboard"
          : userInfo.isAdmin == false
          ? "/dashboard"
          : "/";
      navigation(redirectPath);
    }
  }, [userInfo, navigation]);
  const styles = {
    shadow: {
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  };
  return (
    <>
      {/* <Row className="justify-content-center align-items-center vh-100">
        <Col md={4}>
          {error ? <Message variant="danger">{error}</Message> : null}
          {loading ? <Loader /> : null}
          <Form
            className="border border-1 p-5 rounded-5"
            onSubmit={submitHandler}
          >
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email or username</Form.Label>
              <Form.Control
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Row>
              <Button className="d-grid" variant="primary my-3" type="submit">
                Login
              </Button>
            </Row>
          </Form>
        </Col>
      </Row> */}
      <Row className="justify-content-center align-items-center vh-100 m-0 ">
        <Col
          md={4}
          className="pt-5 px-5 rounded-5 bg-white"
          style={styles.shadow}
        >
          {error ? <Message variant="danger">{error}</Message> : null}
          {loading ? <Loader /> : null}
          <h2 className="fw-bold text-primary text-center mb-0">
            Welcome to tipmaid
          </h2>
          <Row className="m-0">
            <Col className="text-center text-muted">
              <a className="text-success text-decoration-none" href="/terms">
                Terms of use{" "}
              </a>
              and
              <a
                className="text-success text-decoration-none"
                href="/privacyPolicy"
              >
                {" "}
                Privacy Policy
              </a>
            </Col>
          </Row>
          <Form className=" p-5 " onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="fw-bold text-primary">Email</Form.Label>
              <Form.Control
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="fw-bold text-primary">Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                className="text-muted fs-7 text-decoration-underline hover-effect"
                onClick={() => navigation("/reset-password")}
              >
                Forgot password?
              </span>
            </Form.Group>
            <Row className="justify-content-center">
              <Col xs="auto">
                <Button className=" px-5" variant="primary my-3" type="submit">
                  Login
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Login;
