import React from "react";
import { Col, Row } from "react-bootstrap";

const Terms = () => {
  return (
    <>
      <Row className="m-0">
        <Col className="justify-content-center">
          <h3 className=" mt-5 text-center">tipmaid Terms of use</h3>
          <h6 className="text-center text-muted">Last updated: 05/2024</h6>
        </Col>
      </Row>
      <Row className="p-5 m-0">
        <Col>
          <p>
            These Terms of Service (“Terms of Service”) constitute a legally
            binding agreement (“Agreement”) between you (“Users”) and Dock,
            Inc., doing business as “tipmaid”, and its subsidiaries,
            representatives, affiliates, officers and directors (collectively,
            “tipmaid”, “we”, “us”, or “our”) governing your use of the tipmaid
            application (“tipmaid Platform”) and any related content or
            services, including mobile and/or web-based applications and/or
            integrations (collectively the “Services”).
          </p>
          <p>
            PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE
            SERVICES. In this Agreement, the words “including” and “include”
            mean “including, but not limited to.”
          </p>
          <p>
            Your access and use of the Services constitute your agreement to be
            bound by these Terms, which establishes a contractual relationship
            between you and us. If you do not agree to these Terms, you may not
            access or use the Services. These Terms expressly supersede prior
            agreements or arrangements with you. We may immediately terminate
            these Terms or any Services with respect to you, or generally cease
            offering or deny access to the Services or any portion thereof, at
            any time for any reason.
          </p>
          <p>
            Supplemental terms may apply to certain Services and such
            supplemental terms will be disclosed to you in connection with the
            applicable Services. Supplemental terms are in addition to, and
            shall be deemed a part of, the Terms for the purposes of the
            applicable Services.
          </p>
          <p>
            We may amend the Terms related to the Services from time to time.
            Amendments will be effective upon the posting of such updated Terms
            at this location or the amended policies or supplemental terms on
            the applicable Service. Your continued access or use of the Services
            after such posting constitutes your consent to be bound by the
            Terms, as amended.
          </p>
          <p>
            Our collection and use of personal information in connection with
            the Services is as provided in our Privacy Policy located at
            <a
              href=" https://b2b.tipmaid.io/privacyPolicy"
              target="blank"
              className="ms-2"
            >
              {" "}
              b2b.tipmaid.io/privacyPolicy
            </a>
            .
          </p>
          <p>
            YOU ACKNOWLEDGE THAT WE DO NOT PROVIDE HOUSEKEEPING, VALET OR
            BELLMAN SERVICES. OUR SERVICES MAY BE USED BY YOU TO FACILITATE
            CONTACTLESS TIPS FOR HOUSEKEEPERS, VALETS OR BELLMANS. YOU AGREE
            THAT WE HAVE NO RESPONSIBILITY OR LIABILITY TO YOU OR OTHER USERS
            WHO INTERACT THROUGH THE USE OF OUR SERVICES OTHER THAN AS EXPRESSLY
            SET FORTH IN THESE TERMS.
          </p>
          <p>
            WE DO NOT GUARANTEE THE SUITABILITY, SAFETY OR ABILITY OF USERS. IT
            IS SOLELY YOUR RESPONSIBILITY TO DETERMINE YOUR OWN SUITABILITY,
            SAFETY OR ABILITY TO USE OUR SERVICES AND ENGAGE WITH THE TIPMAID
            PLATFORM. WE WILL NOT PARTICIPATE IN DISPUTES BETWEEN YOU AND OTHER
            USERS. BY USING THE SERVICES, YOU ACKNOWLEDGE THAT YOU MAY BE
            EXPOSED TO SITUATIONS INVOLVING OTHER USERS THAT ARE POTENTIALLY
            UNSAFE, OFFENSIVE, HARMFUL TO MINORS, OR OTHERWISE OBJECTIONABLE,
            AND THAT USE OF SERVICES IS AT YOUR OWN RISK AND JUDGMENT. WE SHALL
            NOT HAVE ANY LIABILITY ARISING FROM OR IN ANY WAY RELATED TO YOUR
            TRANSACTIONS OR RELATIONSHIP WITH OTHER USERS.
          </p>
          <h4 className="fw-bold">APPLICABILITY </h4>
          <p>
            By using our Services, you agree to comply with and be legally bound
            by these Terms and all other rules and policies we post from time to
            time whether or not you become a registered User of the Services.
            All Users of tipmaid are required to register an account with us
            <b>(“Account”)</b>. These Terms apply to all Users of the Services
            regardless of how you access or use the Services. Failure to use the
            Services in accordance with these Terms may subject you to severe
            civil and criminal penalties.
          </p>
          <h4 className="fw-bold">ELIGIBILITY </h4>
          <p>
            You represent and warrant that you are at least 18 years old.
            Otherwise, you’re not permitted to use the Service. We reserve the
            right to refuse to offer the Services to any person or entity and
            change eligibility criteria anytime. You are responsible for
            complying with all applicable laws, which may change from time to
            time without notice. The Services are offered for your personal use
            only.
          </p>
          <h4 className="fw-bold">THE TIPMAID PLATFORM</h4>
          <p>
            The Tipmaid Platform provides integrations for the service industry
            (“Clients”) to connect with service industry providers (“Service
            Providers”) to encourage digital gratuity opportunities. Clients and
            Service Providers using the tipmaid Platform are collectively
            referred to herein as “Users.” The tipmaid Platform does not include
            or offer Hospitality Services.
          </p>
          <h4 className="fw-bold">REGISTRATION </h4>
          <p>
            To use the Services as Users of tipmaid, you will be required to
            register your Account. When you set up your Account and User
            profile, you must provide accurate and complete information. It is
            your responsibility to keep your Account information current. You
            are not permitted to do any of the following: <b>(i)</b> select or
            use as a Username a name of another person with the intent to
            impersonate that person; <b>(ii)</b> use as a Username a name
            subject to any rights of a person other than you without appropriate
            authorization; <b>(iii)</b> use, as a Username, a name that is
            otherwise offensive, vulgar or obscene. Do not use another person’s
            Account or registration information for the Services without
            permission, or publish, distribute or post login info for your
            Account. You are responsible for keeping your password secret. You
            must notify us immediately of any breach of security or unauthorized
            use of your Account. You may delete your Account by contacting us at
            admin@dock.bot. We are not liable to you for any unauthorized
            disclosures of your Account information
          </p>
          <h4 className="fw-bold">NETWORK ACCESS AND DEVICES </h4>
          <p>
            You are responsible for obtaining the data network access necessary
            to use the Services. Your mobile network’s data and messaging rates
            and fees may apply if you access or use the Services from a
            wireless-enabled device. You are responsible for acquiring and
            updating compatible hardware or devices necessary to access and use
            the Services and Applications and any updates thereto. We do not
            guarantee that the Services, or any portion thereof, will function
            on any particular hardware or devices. In addition, the Services may
            be subject to malfunctions and delays inherent in the use of the
            Internet and electronic communications.
          </p>
          <h4 className="fw-bold">TEXT MESSAGING</h4>
          <p>
            By engaging with our services, you agree that the Services may send
            you informational text <b>(SMS)</b> messages as part of the normal
            business operation of your use of the Services. You may opt-out of
            receiving text (SMS) messages from us at any time by emailing us at
            admin@dock.bot. Generally, you may not opt-out of communications
            which are not promotional in nature. You acknowledge that opting out
            of receiving text (SMS) messages may impact your use of the
            Services.
          </p>
          <h4 className="fw-bold">U.S. SHORT CODE TERMS OF SERVICE</h4>
          <p>
            Tipmaid’s white labeled short code conversational interfaces are
            designed to facilitate contactless tips to Users. If you are
            experiencing issues with the messaging program you can reply with
            the keyword HELP for more assistance, or you can get help directly
            at admin@dock.bot. Carriers are not liable for delayed or
            undelivered messages. As always, message and data rates may apply
            for any messages sent to you from us and to us from you. You will
            receive messages for the duration of your parking reservation and as
            confirmation of a submitted contactless tip. There may be time to
            time that we offer discounts during events. If you have any
            questions about your text plan or data plan, it is best to contact
            your wireless provider.
          </p>
          <p>
            If you have any questions regarding privacy, please read our{" "}
            <a href=" https://b2b.tipmaid.io/privacyPolicy" target="blank">
              privacy policy
            </a>
            .
          </p>
          <h4 className="fw-bold">PROMOTIONAL CODES </h4>
          <p>
            We may, in our sole discretion, create promotional codes that may be
            redeemed for Account credit or other features or benefits related to
            the Services, subject to terms that we establish on a per
            promotional code basis <b>(“Promo Codes”)</b>. You agree that Promo
            Codes:
            <b> (i)</b> must be used for the intended audience and purpose, and
            in a lawful manner; <b> (ii)</b> may not be duplicated, sold or
            transferred in any manner, or made available to the general public,
            unless expressly permitted by us; <b>(iii)</b> may be disabled by us
            at any time for any reason without liability to us; <b>(iv)</b> may
            only be used pursuant to the specific terms that we establish for
            such Promo Code; <b>(v)</b> are not valid for cash; and <b>(vi)</b>{" "}
            may expire prior to your use. We reserves the right to withhold or
            deduct credits or other features or benefits obtained through the
            use of Promo Codes by you or any other User in the event that we
            determine or reasonably believe that the use or redemption of the
            Promo Code was in error, fraudulent, illegal, or in violation of the
            applicable Promo Code terms or these Terms.
          </p>
          <p>
            Some Users can create promotional codes that may be redeemed for
            Account Credit or other features or benefits related to the
            Services. Users with this ability are limited to parking lot owners
            or managers. These promotional codes can be redeemed for discounted
            or free parking on parking locations. With these promotional codes,
            you also agree that these promo codes: <b> (i)</b> must be used for
            the intended audience and purpose, and in a lawful manner;{" "}
            <b> (ii)</b> may not be duplicated, sold or transferred in any
            manner, or made available to the general public, unless expressly
            permitted by us or the parking lot owner/ manager; <b>(iii)</b> may
            be disabled by us at any time for any reason without liability to
            us; <b>(iv)</b> may only be used pursuant to the specific terms that
            we establish for such Promo Code; <b>(v)</b> are not valid for cash;
            and <>(vi)</> may expire prior to your use. We reserves the right to
            withhold or deduct credits or other features or benefits obtained
            through the use of Promo Codes by you or any other User in the event
            that we determine or reasonably believe that the use or redemption
            of the Promo Code was in error, fraudulent, illegal, or in violation
            of the applicable Promo Code terms or these Terms.
          </p>
          <h4 className="fw-bold">CONTENT AND USER CONTENT </h4>
          <p>
            “Content” includes User photos and information, location
            information, videos, audio clips, written posts and comments,
            information, data, text, software, scripts, graphics, and
            interactive features generated, provided, or otherwise made
            accessible on or through the Services. Content can be posted by us,
            third parties, or Users.
          </p>
          <p>
            All Content that is added, created, uploaded, submitted,
            distributed, or posted to the Services by you or other Users is
            called “User Content.” Some User Content is publicly posted or
            privately transmitted. User Content is the sole responsibility of
            the originator. All Content that you access by using the Services is
            at your own risk and you will be solely responsible for any damage
            or loss to you or any other party resulting therefrom. Sometimes,
            you may delete or remove your User Content, either yourself or
            through a request made through our website. When your User Content
            is deleted, it will be removed from the Services, but it may persist
            in backup copies for a reasonable period of time (but will not,
            following removal, be shared with others).
          </p>
          <p>
            The format and content of the Services is protected by U.S. and
            international copyright. We reserve all rights in relation to our
            copyright (whether owned or licensed to us) and all rights are
            reserved to any of our registered and unregistered trademarks
            (whether owned or licensed to us) which appear on the Services. The
            Services or any portion thereof may not be reproduced, duplicated,
            copied, sold, resold, visited, or otherwise exploited for any
            purpose without our express written consent. You shall maintain, and
            not destroy or obscure, all copyright notices, information, and
            restrictions contained in any Content accessed through the Services
            for Content provided by us, our partners or Users.
          </p>
          <p>
            We are not responsible for anything another User posts on the
            Services. We can’t promise that any Content will be made through the
            Services and we don’t have an obligation to monitor the Services.
            However, we reserve the right to (i) remove, edit or modify any
            Content whenever we want without notice to you for any reason
            (including, but not limited to, upon receipt of claims or
            allegations from third parties or authorities relating to such
            Content or if we are concerned that you may have violated these
            Terms), or for no reason at all and (ii) to remove or block any
            Content from the Services.
          </p>
          <h4 className="fw-bold">LICENSE GRANTS </h4>
          <p>
            Subject to these Terms, we grant each User of the Services
            (including apps downloaded through third party services) a
            worldwide, non-exclusive, non-sublicensable and non-transferable
            license to use Content solely for personal, non-commercial purposes.
            Use, reproduction, modification, distribution or storage of any
            Content for other than personal, non-commercial use is expressly
            prohibited without prior written permission from us or from the
            copyright holder identified in such Content’s copyright notice. You
            shall not sell, license, rent, reverse engineer, decompile, or
            otherwise use or exploit the Service, including any of its
            individual components, any application, or Content, except as
            permitted by applicable law.
          </p>
          <p>
            By submitting User Content through the Services, you grant us a
            worldwide, non-exclusive, royalty-free, fully paid, sublicensable
            and transferable license to use, edit, modify, reproduce,
            distribute, prepare derivative works of, display, perform, and
            otherwise fully exploit the User Content in connection with the
            Services and our business, including without limitation for
            promoting and redistributing part or all of the Services in any
            media formats and through any media channels (including, without
            limitation, APIs, third party websites and feeds). You also grant
            each User of the Site and/or the Services a non-exclusive license to
            access your User Content through the Services, and to use, edit,
            modify, reproduce, distribute, prepare derivative works of, display
            and perform such User Content. For clarity, these license grants to
            us and our Users do not affect your other ownership or license
            rights in your User Content, including the right to grant additional
            licenses to your User Content, unless otherwise agreed in writing.
            You represent and warrant that you have all rights to grant such
            licenses to us without infringement or violation of any third party
            rights, including without limitation, any privacy rights, publicity
            rights, copyrights, contract rights, or any other intellectual
            property or proprietary rights.
          </p>
          <h4 className="fw-bold">PAYMENT</h4>
          <p>
            As a User, you understand and agree that use of the Services will
            result in payments by you for the purposes of gratuity. Charges may
            be inclusive of applicable taxes, where required by law. Charges
            will be inclusive of all software, convenience and processing fees.
            Charges paid by you are final and non-refundable, unless otherwise
            determined by us.
          </p>
          <p>
            Users may use Services to provide digital gratuity to listed and
            approved personnel within the tipmaid Application. As a User you
            understand that the use of our Services will result in payments by
            you. For Users, you agree to all applicable charges for the use of
            Services. Charges paid by you are final and non-refundable, unless
            otherwise determined by us.
          </p>
          <p>
            All Charges are due immediately. If your primary payment method is
            determined to be expired, invalid or otherwise not able to be
            charged, you agree that we may request a secondary payment method to
            facilitate a successful Charge.
          </p>
          <p>
            We also reserve the right to establish, remove, or revise processing
            fees that we charge to Users for use of the Services at any time in
            our sole discretion. We will use reasonable efforts to inform you of
            Fees that may apply, provided that you will be responsible for Fees
            incurred under your Charge regardless of your awareness of such Fees
            or the amounts thereof.
          </p>
          <p>
            We may from time to time provide certain Users with promotional
            offers and discounts that may result in different Charges or Fees
            for the same or similar Services, and you agree that such
            promotional offers and discounts, unless also made available to you,
            shall have no bearing on your use of the Services or the Charges or
            Fees applied to you.
          </p>
          <h4 className="fw-bold">TAXES</h4>
          <p>
            Tax regulations may require us to collect appropriate tax
            information from Users to withhold taxes from payouts. You are
            solely responsible for keeping the information in your tax forms
            current, complete and accurate. If you fail to provide us with
            documentation that we determine to be sufficient to alleviate our
            obligation (if any) to withhold payments to you (e.g. where you fail
            to provide us with a completed IRS Form W-9), we reserve the right
            in our sole discretion to withhold such amounts as required by law
            and terminate your Account.
          </p>
          <p>
            If you earn more than $600 (or another amount prescribed by relevant
            tax authorities) from gratuity, we will send you a year-end IRS Form
            1099 reflecting the amount earned. You may be required to pay income
            taxes based on the amount of income you received from the gratuity.
            You understand and agree that you are solely responsible for
            determining (i) your applicable tax reporting requirements, and (ii)
            the taxes that should be included, and for including taxes to be
            collected or obligations relating to applicable taxes in gratuity.
            You are also solely responsible for remitting to the relevant
            authority any taxes included or received by you. We cannot and do
            not offer tax-related advice to any Users. You agree that we may
            seek additional amounts from you in the event that the taxes
            collected or remitted are insufficient to fully discharge your
            obligations to the applicable tax authority
          </p>
          <h4 className="fw-bold">TIPMAID TAX EXEMPTION </h4>
          <p>
            Users acknowledge that they may receive tips or gratuities in the
            course of using Services, and understands and agrees that they are
            solely responsible for accurately reporting all tips and gratuities
            received during use of Services to the relevant tax authorities. We
            expressly disclaim any responsibility for the accurate reporting of
            tips by the Users. We are not responsible for providing tax advice
            or ensuring compliance with tax regulations regarding tip reporting
            or tip income. We shall not be held responsible for any tax
            obligations arising from the reporting or non-reporting of tips by
            the Users.
          </p>
          <h4 className="fw-bold">CANCELLATION AND REFUNDS</h4>
          <p>
            Cancellations and refunds are not applicable. All charges are final
            unless disputed by third parties.
          </p>
          <h4 className="fw-bold">COVENANTS</h4>
          <p>
            You are responsible for all of your activity in connection with the
            Services. We are not involved with the in-person interactions
            between Users. As a condition of use, you agree not to use the
            Services for any purpose that is prohibited by these Terms.
          </p>
          <p>
            You covenant to Company that you will not (and shall not permit any
            third party to) either <b>(a)</b> take any action or <b>(b)</b>{" "}
            upload, download, post, submit or otherwise distribute or facilitate
            distribution of any Content on or through the Service, including
            without limitation any User Content, that: <b>(i)</b> infringes any
            patent, trademark, trade secret, copyright, right of publicity or
            other right of any other person or entity or violates any law or
            contractual duty (see our DMCA Copyright Policy); <b>(ii)</b> you
            know is false, misleading, misrepresentative, untruthful or
            inaccurate;<b> (iii)</b> is unlawful, threatening, abusive,
            harassing, defamatory, libelous, deceptive, fraudulent, invasive of
            another’s privacy, tortious, obscene, vulgar, pornographic,
            offensive, profane, contains or depicts nudity, contains or depicts
            sexual activity, or is otherwise inappropriate as determined by us
            in our sole discretion;<b>v</b>
            constitutes unauthorized or unsolicited advertising, junk or bulk
            e-mail (“spamming”); <b>(v)</b> contains software viruses or any
            other computer codes, files, or programs that are designed or
            intended to disrupt, damage, limit or interfere with the proper
            function of any software, hardware, or telecommunications equipment
            or to damage or obtain unauthorized access to any system, data,
            password or other info of ours or of any third party; <b>(vi)</b>{" "}
            impersonates any person or entity, including any of our employees or
            representatives; <b>(vii)</b> is used to stalk, harass or otherwise
            annoy any other User or collect or store information about any other
            User other than for the purposes of completing a transaction through
            the Services; <b>(viii)</b>
            offers any parking space to which you do not have legal rights;{" "}
            <b>(ix)</b>
            discloses any other User’s identification documents or sensitive
            financial information; or <b>(x)</b> encourages or assists a third
            party to engage in any of the foregoing.
          </p>
          <p>
            Further, You shall not: <b>(i)</b> take any action that imposes or
            may impose (as determined by us in our sole discretion) an
            unreasonable or disproportionately large load on our (or our third
            party providers’) infrastructure; <b>(ii)</b> interfere or attempt
            to interfere with the proper working of the Services or any
            activities conducted on the Services; <b>(iii) </b>bypass,
            circumvent or attempt to bypass or circumvent any measures we may
            use to prevent or restrict access to the Services (or other
            accounts, computer systems or networks connected to the Services);{" "}
            <b>(iv)</b> run any form of auto-responder or “spam” on the
            Services; <b>(v)</b> use manual or automated software, devices, or
            other processes to “crawl” or “spider” any page of the Site;{" "}
            <b>(vi)</b> harvest or scrape any Content from the Services;{" "}
            <b>(vii)</b>
            otherwise take any action in violation of our guidelines and
            policies; or <b>(viii)</b> encourage or assist a third party to
            engage in any of the foregoing.
          </p>
          <p>
            We reserve the right to access, read, preserve, and disclose any
            info as we reasonably believe is necessary to<b> (i)</b> satisfy any
            applicable law, regulation, legal process or governmental request,
            <b>(ii) </b>enforce these Terms, including investigation of
            potential violations hereof, <b>(iii)</b> detect, prevent, or
            otherwise address fraud, security or technical issues, <b>(iv)</b>{" "}
            respond to User support requests, or <b>(v) </b>protect the rights,
            property or safety of us, our Users and the public.
          </p>
          <h4 className="fw-bold">THIRD PARTY SERVICES</h4>
          <p>
            The Services may acquire information from third party services, such
            as Facebook, Foursquare, LinkedIn, Twitter and other social networks
            and internet sources (“Third Party Services”). The Services may
            permit you to link to and otherwise interact with third party
            resources on the Internet: you do so at your own risk. The Service
            may allow you to post geolocation data and other information to
            these Third Party Services. We don’t control these websites or
            applications. You acknowledge and agree that we shall not be
            responsible or liable, directly or indirectly, for any damage or
            loss caused or alleged to be caused by or in connection with the use
            of or reliance on any such content, goods or services available on
            or through any Third Party Services.
          </p>
          <h4 className="fw-bold">USER DISPUTES AND DISPUTE RESOLUTION</h4>
          <p>
            We are not responsible for your interactions with other Users. We
            reserve the right, but have no obligation, to monitor disputes
            between Users. Any dispute between you and another User must be
            resolved between yourselves as follows:
          </p>
          <p>
            The parties agree that if any dispute arises between Users of the
            Services, related to the Services, the parties will attempt to
            resolve such dispute through direct communications for a period of
            30 days. Any agreement reached between the parties may be paid
            directly by one party to the other. In the event that a dispute
            cannot be resolved directly it is agreed that either party may take
            whatever legal action it deems necessary and appropriate.
          </p>
          <p>
            Any dispute between Users shall be governed by and construed in
            accordance with the laws of the state in which the Users are
            located, including its conflicts of law rules. The parties to the
            dispute agree that any dispute between them arising from or relating
            to the use of Services thereof shall be governed by the exclusive
            jurisdiction and venue of the state and federal courts of the county
            and state in which the Hotel is located.
          </p>
          <p>
            Should Users contact their financial institution to
            chargeback/dispute a credit/debit card transaction for Charges,
            tipmaid shall use all reasonable methods to resolve the disputes
            with the User. Tipmaid shall comply with all requests for
            information from the merchant processor to resolve the dispute in
            tipmaid’s favor. If a dispute is settled in Users favor, the
            disputed funds + disputed transaction fee will be compensated by
            tipmaid.
          </p>
          <h4 className="fw-bold">
            DISPUTES WITH TIPMAID AND DISPUTE RESOLUTION
          </h4>
          <p className="">
            <b>
              To protect us, we may delay a withdrawal, in certain situations,
              including if we need to confirm whether or not payments to your
              account have been subject to a reversal (for example, as a result
              of a{" "}
              <a
                href="https://www.paypal.com/us/legalhub/useragreement-full?locale.x=en_US"
                target="blank"
              >
                chargeback
              </a>
              , bank reversal, or{" "}
              <a
                href="https://www.paypal.com/us/legalhub/useragreement-full?locale.x=en_US"
                target="blank"
              >
                dispute by a buyer
              </a>
              )
            </b>
          </p>
          <p className="my-2">For any dispute between you and the tipmaid:</p>
          <p>
            You agree that any dispute, claim or controversy arising out of or
            relating to these Terms or the breach, termination, enforcement,
            interpretation or validity thereof or the use of the Services
            (collectively, “Disputes”) will be settled by binding arbitration
            between you and tipmaid, except that each party retains the right to
            bring an individual action in small claims court and the right to
            seek injunctive or other equitable relief in a court of competent
            jurisdiction to prevent the actual or threatened infringement,
            misappropriation or violation of a party’s copyrights, trademarks,
            trade secrets, patents or other intellectual property rights. You
            acknowledge and agree that you and tipmaid are each waiving the
            right to a trial by jury or to participate as a plaintiff or class
            in any purported class action or representative proceeding. Further,
            unless both you and tipmaid otherwise agree in writing, the
            arbitrator may not consolidate more than one person’s claims, and
            may not otherwise preside over any form of any class or
            representative proceeding. If this specific paragraph is held
            unenforceable, then the entirety of this “Dispute Resolution”
            section will be deemed void. Except as provided in the preceding
            sentence, this “Dispute Resolution” section will survive any
            termination of these Terms.
          </p>
          <p>
            The arbitration will be administered by the American Arbitration
            Association (“AAA”) in accordance with the Commercial Arbitration
            Rules and the Supplementary Procedures for Consumer Related Disputes
            (the “AAA Rules”) then in effect, except as modified by this
            “Dispute Resolution” section. The Federal Arbitration Act will
            govern the interpretation and enforcement of this Section.
          </p>
          <p>
            A party who desires to initiate arbitration must provide the other
            party with a written Demand for Arbitration as specified in the AAA
            Rules. The arbitrator will be either a retired judge or an attorney
            licensed to practice law in the state defined by Users and will be
            selected by the parties from the AAA’s roster of consumer dispute
            arbitrators. If the parties are unable to agree upon an arbitrator
            within seven days of delivery of the Demand for Arbitration, then
            the AAA will appoint the arbitrator in accordance with the AAA
            Rules.
          </p>
          <p>
            Unless you and tipmaid otherwise agree, the arbitration will be
            conducted in the county where you reside. If your claim does not
            exceed $10,000, then the arbitration will be conducted solely on the
            basis of documents you and the Company submit to the arbitrator,
            unless you request a hearing or the arbitrator determines that a
            hearing is necessary. If your claim exceeds $10,000, your right to a
            hearing will be determined by the AAA Rules. Subject to the AAA
            Rules, the arbitrator will have the discretion to direct a
            reasonable exchange of information by the parties, consistent with
            the expedited nature of the arbitration.
          </p>
          <p>
            The arbitrator will render an award within the time frame specified
            in the AAA Rules. The arbitrator’s decision will include the
            essential findings and conclusions upon which the arbitrator based
            the award. Judgment on the arbitration award may be entered in any
            court having jurisdiction thereof. The arbitrator’s award damages
            must be consistent with the terms of the “Limitation of Liability”
            section below as to the types and the amounts of damages for which a
            party may be held liable. The arbitrator may award declaratory or
            injunctive relief only in favor of the claimant and only to the
            extent necessary to provide relief warranted by the claimant’s
            individual claim.
          </p>
          <p>
            Your responsibility to pay any AAA filing, administrative and
            arbitrator fees will be solely as set forth in the AAA Rules.
          </p>
          <p>
            Notwithstanding the provisions of the modification-related
            provisions above, if the Company changes this “Dispute Resolution”
            section after the date you first accepted these Terms (or accepted
            any subsequent changes to these Terms), you may reject any such
            change by providing us written notice of such rejection by mail or
            hand delivery to: Dock, Inc [1717 Pennsylvania Ave NW 10th FL
            Washington DC 20006], or by email from the email address associated
            with your Account to: [admin@dock.bot], within 30 days of the date
            such change became effective, as indicated in the “Last update” date
            above. In order to be effective, the notice must include your full
            name and clearly indicate your intent to reject changes to this
            “Dispute Resolution” section. By rejecting changes, you are agreeing
            that you will arbitrate any Dispute between you and the Company in
            accordance with the provisions of this “Dispute Resolution” section
            as of the date you first accepted these Terms (or accepted any
            subsequent changes to these Terms). For greater clarity, your access
            to the Services for purposes of reviewing updates to these Terms
            will not be deemed an acceptance of the updated Terms for purposes
            of rejecting the Dispute Resolution provision as contemplated
            herein.
          </p>
          <h4 className="fw-bold">RELEASE OF CLAIMS AGAINST COMPANY</h4>
          <p>
            All Users each hereby fully and forever release tipmaid and its
            partners, employees, attorneys, agents, successors, representatives,
            shareholders, parent companies, subsidiaries, affiliated companies
            and assigns, jointly and severally (collectively, the “Company
            Parties”), and hereby fully and forever discharge and agree to hold
            tipmaid’s Parties harmless from and against any and all claims and
            causes of action, in law or in equity, disputes, suits, debts,
            liens, rights, contracts, agreements, acts, promises, liabilities,
            obligations, demands, damages, losses, costs, fees (including,
            without limitation, those of attorneys) and expenses, of whatsoever
            kind or nature, whether known or unknown, suspected or unsuspected,
            which exist, may have existed or which may in the future exist
            between Users on the one hand and tipmaid Parties, or any of them,
            on the other hand in connection with this agreement.
          </p>
          <p>
            Waiver of California Civil Code Section 1542. Users each hereby
            understands and agrees that the general release of tipmaid’s Parties
            set forth above extends to all claims of every nature and kind,
            whether now known or unknown, suspected or unsuspected, which exist,
            may have existed or which may in the future exist. With respect to
            such release, Users each expressly waives and relinquishes any and
            all rights under Section 1542 of the California Civil Code, which
            provides as follows:
          </p>
          <p>
            “A general release does not extend to claims which the creditor does
            not know or suspect to exist in his or her favor at the time of
            executing the release, which if known by him or her must have
            materially affected his or her settlement with the debtor.”
          </p>
          <h4 className="fw-bold">RELEASE OF CLAIMS AGAINST COMPANY</h4>
          <p>
            We take customer service and User experience very seriously. We love
            feedback. Therefore, we encourage you to leave detailed honest
            feedback about our Services and other Users. In the event that
            anything inappropriate, offensive, disturbing or potentially
            criminal happens, you must immediately report this and the
            responsible party to the local police. Then, please contact us with
            the name of the police station, the police report number, date and
            time of the incident, parties involved, and any other facts. Your
            report to us does not require us to take legal action or engage with
            any legal or governmental authorities on your behalf or incur any
            liability upon us to you or any other party.
          </p>
          <h4 className="fw-bold">TERMINATION </h4>
          <p>
            We may terminate your access to all or any part of the Services at
            any time, with or without cause, with or without notice, effective
            immediately, which may result in the forfeiture and destruction of
            all info associated with your Account. If you wish to terminate your
            Account, you may do so by contacting us or through the Account
            settings. All provisions of these Terms which by their nature should
            survive termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity
            and limitations of liability.
          </p>
          <h4 className="fw-bold">WARRANTY DISCLAIMER </h4>
          <p>
            WE SHALL NOT BE LIABLE TO YOU FOR INDIRECT, INCIDENTAL, SPECIAL,
            EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST
            PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE, EVEN IF WE
            HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. WE SHALL NOT
            BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES INCURRED BY YOU
            ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR
            INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR
            RELATIONSHIP BETWEEN YOU AND ANY OTHER USER, EVEN IF WE HAVE BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. WE SHALL NOT BE LIABLE
            FOR DELAY OR FAILURE IN PERFORMANCE. IN NO EVENT SHALL OUR TOTAL
            LIABILITY TO YOU IN CONNECTION WITH THE SERVICES FOR ALL DAMAGES,
            LOSSES AND CAUSES OF
          </p>
          <p>
            YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR
            OWN RISK.. WE DO NOT COMPLETE BACKGROUND CHECKS ON USERS OR MAKE ANY
            REPRESENTATIONS ABOUT THE LOCATION, SAFETY, OR QUALITY OF THE USERS.
            THE SERVICES AND CONTENT ARE PROVIDED “AS-IS”, “AS-AVAILABLE,” WITH
            ALL FAULTS, AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
            INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE,
            NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
            PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR
            USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. WE, AND OUR
            DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS AND CONTENT
            PROVIDERS DO NOT WARRANT THAT:<b> (I) </b>THE SERVICES WILL BE
            SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; <b>(II)</b>{" "}
            ANY DEFECTS OR ERRORS WILL BE CORRECTED; <b>(III)</b> ANY CONTENT OR
            SOFTWARE AVAILABLE AT OR THROUGH THE SERVICES IS FREE OF VIRUSES OR
            OTHER HARMFUL COMPONENTS; OR <b>(IV)</b> THE RESULTS OF USING THE
            SERVICES WILL MEET YOUR REQUIREMENTS. YOUR USE OF THE SERVICES IS
            SOLELY AT YOUR OWN RISK. SOME STATES DO NOT ALLOW LIMITATIONS ON
            IMPLIED WARRANTIES, SO THE FOREGOING LIMITATIONS MAY NOT APPLY TO
            YOU.
          </p>
          <h4 className="fw-bold">INDEMNIFICATION </h4>
          <p>
            You agree to indemnify and hold Dock, Inc. doing business as
            “tipmaid” and its officers, directors, employees and agents,
            harmless from any and all claims, demands, losses, liabilities, and
            expenses (including attorneys’ fees), arising out of or in
            connection with: <b>(i)</b> your use of the Services; <b>(ii)</b>{" "}
            your breach or violation of any of these Terms; <b>(iii)</b> our use
            of your User Content; or <b>(iv)</b> your violation of the rights of
            any third party.
          </p>
          <h4 className="fw-bold">GENERAL TERMS </h4>
          <p>
            Governing Law and Jurisdiction. These Terms shall be governed by and
            construed in accordance with the laws of the State of Delaware,
            including its conflicts of law rules. You agree that any dispute
            arising from or relating to the subject matter of these Terms shall
            be governed by the exclusive jurisdiction and venue of the state and
            Federal courts of Delaware. The foregoing will not apply to disputes
            between Users.
          </p>
          <p>
            <b>Entire Agreement and Severability.</b> These Terms are the entire
            agreement between you and us with respect to the Services, including
            use of the Site, and supersede all prior or contemporaneous
            communications and proposals (whether oral, written or electronic)
            between you and us with respect to the Services. If any provision of
            these Terms is found to be unenforceable or invalid, that provision
            will be limited or eliminated to the minimum extent necessary so
            that these Terms will otherwise remain in full force and effect and
            enforceable.
          </p>
          <p>
            <b>Force Majeure.</b> We shall not be liable for any failure to
            perform any obligations where such failure results from any cause
            beyond our reasonable control, including, without limitation,
            mechanical, electronic or communications failure or degradation.
          </p>
          <p>
            <b> Assignment.</b> These Terms are personal to you, and are not
            assignable, transferable or sub licensable by you except with our
            prior written consent. We may assign, transfer or delegate any of
            our rights and obligations hereunder without consent.
          </p>
          <p>
            <b>Agency.</b> No agency, partnership, joint venture, or employment
            relationship is created as a result of these Terms and neither party
            has any authority of any kind to bind the other in any respect.
          </p>
          <p>
            <b>Notices.</b> Unless otherwise specified in these Terms, all
            notices under these Terms will be in writing and will be deemed to
            have been duly given when received, if personally delivered or sent
            by certified or registered mail, return receipt requested; when
            receipt is electronically confirmed, if transmitted by facsimile or
            e-mail; or the day after it is sent, if sent for next day delivery
            by recognized overnight delivery service. Electronic notices should
            be sent to [admin@dock.bot].
          </p>
          <p>
            <b> No Waiver.</b> All waivers must be in writing through one of our
            authorized representatives. If either party fails to exercise any
            rights, this is not deemed a waiver of any further rights hereunder.
          </p>
        </Col>
      </Row>
    </>
  );
};

export default Terms;
